
import React, { Component, useEffect } from 'react'
// import { Footer } from '../../components/StartupPage/Footer';



const Pricing = () => {


    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" className='w-full bg-white min-h-screen flex flex-col items-center md:items-start justify-center md:justify-start overflow-hidden'>


            <div className='mt-28 md:mt-48  '>
                <h1 className='text-3xl md:text-3xl font-bold mx-5 md:mx-20 mb-10'>Pricing</h1>
            </div>
            <div className=" flex flex-col  mb-40 px-2 md:mx-20">

                <p className='capitalize'>
                   A MEMBERSHIP REGISTRATION FEE OF ₹250 IS APPLICABLE FOR JOINING THE IEDC TKMCE ORGANIZATION. ONE TIME PAYMENT OF ₹(CORRESPONDING TO THE REGISTERED EVENT) IS REQUIRED FOR AN INDIVIDUAL/OR TEAM TO PARTICIPATE IN ANY EVENT/WORKSHOP. AFTER THE EVENT GOODIES(DEPENDING ON THE PARTICIPATED EVENT) WILL BE ALLOCATED.DETAILED PRICING CAN BE FOUND IN /EVENTS/EVENT_DETAILS/EVENT_ID.FOR ANY QUERIES REGARDING THE PRICING CONTACT : IEDC@TKMCE.AC.IN           </p></div>
            {/* <Footer /> */}
        </div>
    )
}

export default Pricing;


