import React from 'react'
import { branches } from '../../constants/data'
// import TableRow from './TableRow'



const ProfileTable = ({ props }) => {
    return (
        <div className="rounded-lg bg-transparent w-full mb-8 overflow-hidden  shadow-xs text-db-text-p">
            <div className="w-full  flex flex-col shadow-lg">

                <div className=' flex flex-row w-full px-4 md:px-0 md:justify-center text-xs font-semibold tracking-wide text-left uppercase    '>
                    <div className='my-3 text-xl'>ABOUT</div>
                </div>
                <div className='flex flex-col text-xs font-semibold tracking-wide text-left'>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Membership status</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.iedc_membership_status === true ? <p className='text-green-500'>Active</p> : <p className='text-red-500'>Inactive</p>}


                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Admission number</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.admission_no}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Name</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.name}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Phone number</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.phone_number}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Branch</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {branches[branches.findIndex((a) => a.slug === props?.branch)]?.label}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Bio</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.bio}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>Year</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.course_duration_from}-{props?.course_duration_to}

                        </div>
                    </div>
                    <div className='flex flex-col items-start md:flex-row border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3'>College</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3 '>
                            {props?.college}

                        </div>
                    </div>
                    <div className='flex flex-col border-b border-b-gray-800 mb-3 md:mb-0 md:items-center justify-between '>

                        <div className=' text-sm px-4 py-1 md:py-3 w-full text-left md:text-center'>Why IEDC</div>
                        <div className='  text-gray-400 text-xs px-4 py-1 md:py-3  w-full text-left md:text-center bg-transparent'>
                            {props?.why_iedc}

                        </div>
                    </div>




                </div>


            </div>

        </div>
    )
}

export default ProfileTable

