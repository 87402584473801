import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, S3_URL } from '../../constants/urls';

const EventCard = ({ certificate, name, description, eventimage, slug, registered, state, event_date, dashboard }) => {
  const navigate = useNavigate();
  const month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  return (

    <button
      onClick={() => { if (certificate) window.open(eventimage, '_blank'); else navigate(`/events/event_details/${slug}`) }}
      className={`flex flex-col mb-10 py-5   group overflow-hidden rounded w-full md:w-80 m-2 ${dashboard && "bg-db-top pt-0"}`}>
      <div className=' w-full h-full  duration-100'

      >
        <img src={eventimage} alt='event' className='  w-full' />
      </div>
      <div className='flex flex-col w-full h-1/2 relative text-dark '>
        <div className='flex flex-col items-start text-base' data-aos="fade-in" data-aos-duration="300">

          {
            event_date !== null && event_date !== undefined &&
            <div className={`mt-2 w-fit flex px-1 text-md flex-row items-start justify-center text-gray-500 ${dashboard && "text-gray-300"}`}>

              <p className='mr-1 '>
                {new Date(event_date).getDate()}
              </p>
              <p className=''>
                {month[new Date(event_date).getMonth()]}
              </p>
            </div>
          }
          <h1 className={`mt-4 text-left font-semibold text-xl md:text-2xl ${dashboard && "text-gray-300 px-2"} ${certificate && "text-gray-400"}`}>
            {name}
          </h1>

        </div>

        {description && description.length > 0 &&
          <div className='flex flex-row items-start justify-between mt-2'>
            <p className={` w-4/5 text-left text-md ${dashboard ? "text-gray-300 px-2":"text-gray-600"}`} data-aos="fade-in" data-aos-duration="300" style={{

              lineHeight: '18px',


            }}>
              {description?.slice(0, 120)} ...
            </p>


          </div>}




      </div>
    </button>

  )
}

export default EventCard