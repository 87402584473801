import React, { useEffect, useState } from 'react'
import EventCard from '../../components/Events/EventCard'
import SwipeEventCard from '../../components/Events/SwipeEventCard';
// import { Footer } from "../../components/HomePage/Footer";



import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import "./styles.css";
import { get_events } from '../../apis/events';
import useFetch from '../../hooks/useFetch';
import { BASE_URL } from '../../constants/urls';
import SwiperCore, { Autoplay } from 'swiper';
import Header from '../../components/general/Header';
import bgimg from '../../assets/bg2.jpg'
import LocalLoader from '../../components/LocalLoader';

import plus from '../../svgs/plus-pattern.png'


const EventsPage = () => {
    const { error, isPending, data: events } = useFetch(BASE_URL + '/events/all-events');
    SwiperCore.use([Autoplay]);
    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" className='w-full flex flex-col bg-white min-h-screen'>
            <div className="">



                <div className=' flex flex-col w-full items-center text-center md:text-left'>

                    <div className='mt-28 mb-20 md:my-48  flex justify-start w-full'>
                        <h1 className='text-3xl md:text-7xl font-bold mx-5 md:mx-80'>Events</h1>
                        {/* <img data-aos-duration="300" data-aos="fade-in" alt="" src={plus} class="absolute h-64 hidden lg:block right-0 top-0 mt-28" /> */}

                    </div>
                    <div className='md:px-10 px-4 mb-5 w-full md:w-11/12 xl:w-3/4 flex flex-wrap'>
                        {events !== null &&
                            events.map((event, index) => (
                                event.visible_in_front &&
                                <EventCard event_date={event.event_start} slug={event.slug} name={event.event_name} description={event.description} eventimage={event.image} key={index} className="" />
                            ))
                        }
                        {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
                        {isPending && <div className='p-2 w-full text-center text-black relative'><LocalLoader /></div>}
                        {!error && events?.length === 0 && <div className='p-2 w-full text-center text-black'>No events</div>}


                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default EventsPage
