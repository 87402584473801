import axios from "axios"
import { BASE_URL } from "../constants/urls"

export async function register_event(token, data) {
    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL + '/events/team/',
            headers: { "Content-Type": "application/json", "Authorization": `token ${token}` },
            data

        })

        return {
            status: response.status,
            response: response.data
        }
    } catch (e) {
        return {
            status: e.response?e.response.status:e.status,
            errMssg: e.response.data
        }
    }
}