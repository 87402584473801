import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import { ContactUs } from '../../components/HomePage/ContactUs';
import NavBar from '../../components/NavBar';
import useWindowSize from '../../hooks/useWindowSize';
import EventsPage from '../Events/EventsPage';
import ExecomPage from '../Execom/ExecomPage';
import { HomePage } from '../Home';
import NotFound from '../NotFound';
import StartUps from '../StartupPages/StartUps';
import Contact from "../Contact";
import Stories from '../Stories';
import EventView from '../Events/EventView';
import CreateTeam from '../Events/CreateTeam';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import DareToDevelop from '../DareToDevelop';
import TermsAndConditions from '../TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import Pricing from '../Pricing';
import RefundPolicy from '../RefundPolicy';
import Verify from '../Verify';
import ExecomDetails from '../Execom/ExecomDetails';
import SeedsPage from '../Seeds';

function GeneralPage(props) {

    const [width, height] = useWindowSize();
    const [navBarShow, setNavBarShow] = useState(true);
    const [Page, setPage] = useState(<HomePage />);
    const { page, subPage, sub } = useParams();

    const { loadingState } = useContext(LoaderContext);
    const [loading, setLoading] = loadingState;


    useEffect(() => {
        if (width < 768)
            setNavBarShow(false);
        else
            setNavBarShow(true);

    }, [width]);
    useEffect(() => {
        switch (page) {
            case "":
                setPage(<HomePage />);
                break;
            case "startups":
                setPage(<StartUps />);
                break;
            case "dare2develop":
                setPage(<DareToDevelop />);
                break;
            case "team":
                setPage(<ExecomPage />);
                break;
            case "verify":
                if (subPage !== undefined && sub === undefined)
                    setPage(<Verify />);
                break;
            case "events":
                if (subPage !== undefined)
                    if (sub !== undefined)
                        setPage(<CreateTeam />);
                    else
                        setPage(<EventView />);
                else
                    setPage(<EventsPage />);
                break;
            case "stories":
                setPage(<Stories />);
                break;
            case "contact":
                setPage(<Contact />);
                break;
            case "terms_and_conditions":
                setPage(<TermsAndConditions />);
                break;
            case "privacy_policy":
                setPage(<PrivacyPolicy />);
                break;
            case "pricing":
                setPage(<Pricing />);
                break;
            case "refund_policy":
                setPage(<RefundPolicy />);
                break;
            case "execom":
                setPage(<ExecomDetails/>);
                break;
            case "seeds":
                setPage(<SeedsPage/>);
                break;
            default:
                setPage(<NotFound />);
                break;
        }
    }, [page, subPage, sub]);

    return (
        <div className='w-full max-w-screen min-h-screen flex flex-col justify-between items-center overflow-x-hidden'>
            <NavBar dare2develop={page === "dare2develop" ? true : false} windowSize={{ width, height }} navBarShow={navBarShow} setNavBarShow={setNavBarShow} text_black={(subPage !== undefined || page === "startups" || page === "events" || page === "stories" || page === "team" || page === "pricing" || page === "refund_policy" || page === "terms_and_conditions" || page === "privacy_policy" || page === "execomdetails") ? true : false || page === "verify"} />
            <div className='w-full relative'>
                <Loader loading={loading} />
                {Page}
            </div>
            <Footer />
        </div>
    );
}

export default GeneralPage;