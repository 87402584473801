import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Parallax, Mousewheel, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_URL } from '../../constants/urls';
import useFetch from '../../hooks/useFetch';
import LocalLoader from '../LocalLoader';


function Testimonial(props) {

    const { error, isPending, data: testimonial } = useFetch(BASE_URL + '/gallery/testimonials');

    return (
        <div className='w-full flex flex-col items-center p-5 mt-10'>
            <div className='w-full md:w-1/2 lg:w-[48%] flex flex-col items-start'>
                <p data-aos-duration="300" data-aos="fade-in" className="uppercase text-red-400 font-bold text-md subHeading mb-20 lg:px-20">Testimonial</p>
                {
                    testimonial !== null &&
                    <Swiper style={{
                        "--swiper-navigation-color": "rgba(255, 255, 255, 0.272)",
                        "--swiper-pagination-color": "rgba(255, 255, 255, 0.272)",
                        "--swiper-pagination-bullet-height": "5px",
                        "--swiper-pagination-bullet-width": "15px",
                        height: '75%',
                    }} parallax={true}

                        slidesPerView={1}
                        // spaceBetween={30}
                        pagination={{
                            // clickable: true,
                            type: "fraction",
                        }}
                        navigation={true}
                        modules={[Parallax, Pagination, Navigation]} className=" w-full ">
                        {testimonial.data !== null && testimonial.data.map((e, index) => (
                            <SwiperSlide style={{ listStyle: "none", height: 'full', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }} key={index} className="mb-16 lg:px-20">
                                <div className='flex flex-col items-start w-full h-64'>
                                    <p data-aos-duration="300" data-aos="fade-in" className='text-2xl mb-3 font-semibold text-left text-ellipsisi overflow-hidden h-40'>"{e.message}"</p>
                                    <p data-aos-duration="300" data-aos="fade-in" className='text-lg font-bold mb-0'>{e.name}</p>
                                    <p data-aos-duration="300" data-aos="fade-in" className='text-base text-gray-500 font-semibold'>{e.former_role}</p>
                                </div>
                                <div>
                                </div>
                            </SwiperSlide>
                        ))
                        }
                    </Swiper>
                }
                {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
                {isPending && <div className='p-2 w-full text-center relative'><LocalLoader /></div>}
                {!error && testimonial?.data?.length === 0 && <div className='p-2 w-full text-center'>No testimonial</div>}
            </div>
        </div>
    );
}

export default Testimonial;