import React, { useContext } from 'react'
import MetricCard from '../../components/Dashboard/MetricCard'
import Table from '../../components/Dashboard/Table'
import { Link } from "react-router-dom";
import { UserContext } from '../../context/UserContext';
import Usericon from '@material-ui/icons/Person'

import sm from "../../assets/dashboard/schedule meeting.jpg"
import ml from "../../assets/dashboard/maker_lib.jpg"
import business from "../../assets/dashboard/business.png"
import sa from "../../assets/dashboard/server access.jpg"
import pp from "../../assets/dashboard/project proposal.png"
import e from "../../assets/dashboard/events.jpg"
import pi from "../../assets/dashboard/pre incubation.jpg"

function Dashboard() {

    const { userState } = useContext(UserContext);
    const [userDetails, setUserDetails] = userState;
    const details = [
        {
            name: "Maker Library",
            description: "Book your components virtually.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
            </svg>,
            path: '/makerlibrary'
        },
        {
            name: "Server Access",
            description: "Host your website freely and boost your startup.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                />
            </svg>,
            path: '/server_access_form'
        },
        {
            name: "Project Proposal",
            description: "Submit your Project proposals online.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
            </svg>,

            path: '/project_proposal'
        },
        {
            name: "Events and Programs",
            description: "Registered events and programs.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>,
            path: '/registered_events'
        },
        {
            name: "PreIncubation",
            description: "Start on the right path with our expert opinion.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>,


            path: '/preincubation'
        },
        {
            name: "Business Model",
            description: "Submit your model for evaluation from our expert mentor pool.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                />
            </svg>,
            path: '/business_model'
        },
        {
            name: "Schedule Meeting",
            description: "Digitalise all your meetings with a personalised calendar.",
            svg: <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                />
            </svg>,
            path: '/schedule_meeting'
        },
    ];
    return (
        <div data-aos="fade-up" data-aos-duration="300" className='flex flex-col p-0  h-full w-full bg-db '>
            <main className='w-full p-0'>
                <div className="flex flex-col md:flex-row w-full">
                    <section className='w-full'>
                        <div id="main" className="w-full   text-white ">

                            {/* <div className=" w-full">
                                {
                                    details.map((content, index) => (
                                        <div key={index} className={`${index === 0 && "w-1/2"} p-6  h-64 `}>
                                            <MetricCard name={content.name} path={content.path} description={content.description} svg={content.svg} className='' />
                                        </div>
                                    ))
                                }
                            </div> */}
                            <div className='w-full  text-white text-xl md:text-2xl font-bold md:h-[120vh] flex flex-col md:flex-row md:px-10 py-14'>
                                <div className='flex p-1 flex-col w-full md:w-1/3 h-full'>
                                    <Link to="makerlibrary" style={{ backgroundImage: `url("${ml}")` }} className='bg-cover w-full h-44 md:h-3/5 xl:h-3/5 bg-db-top rounded-xl'>
                                        <p className='p-5 py-8'>Maker Library</p>
                                    </Link>
                                    <Link to="business_model" style={{ backgroundImage: `url("${business}")` }} className='bg-cover w-full h-44 md:h-2/5 xl:h-2/5 bg-db-top rounded-xl mt-3 md:mt-5'>
                                        <p className='p-5 py-8'>Business model</p>

                                    </Link>
                                </div>
                                <div className='flex flex-col w-full md:w-2/3 p-1 md:px-3 md:px-5 pt-2 h-full'>
                                    <div className='flex mb-3 md:mb-0 flex-col md:flex-row w-full justify-between md:h-2/5'>
                                        <Link to="schedule_meeting" style={{ backgroundImage: `url("${sm}")` }} className='w-full bg-cover md:w-[48%] bg-db-top h-44 md:h-full rounded-xl'>
                                            <p className='p-5 py-8'>Schedule meeting</p>

                                        </Link>
                                        <Link to="preincubation" style={{ backgroundImage: `url("${pi}")` }} className='w-full bg-cover md:w-[49%] mt-3 md:mt-0 bg-db-top h-44 md:h-full rounded-xl'>
                                            <p className='p-5 py-8'>Pre Incubation</p>

                                        </Link>
                                    </div>
                                    <div className='flex flex-col h-3/5 mt-0 md:mt-5'>
                                        <div className='flex flex-col md:flex-row md:h-2/3'>

                                            <Link to="server_access_form" style={{ backgroundImage: `url("${sa}")` }} className='w-full bg-cover md:w-1/3 bg-db-top h-44 md:h-full rounded-xl md:mr-1'>
                                                <p className='p-5 py-8'>Server access</p>

                                            </Link>
                                            <Link to="project_proposal" style={{ backgroundImage: `url("${pp}")` }} className='w-full bg-cover md:w-2/3 bg-db-top h-44 md:h-full mt-3 md:mt-0 rounded-xl md:ml-4'>
                                                <p className='p-5 py-8'>Project Proposal</p>

                                            </Link>
                                        </div>
                                        <Link to="registered_events" style={{ backgroundImage: `url("${e}")` }} className='w-full bg-cover bg-db-top h-44 md:h-1/3 mt-3 md:mt-5 rounded-xl'>
                                            <p className='p-5 py-8'>Events and Programs</p>

                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default Dashboard
