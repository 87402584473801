import React, { Component, useContext, useState } from 'react'
import Header from '../components/general/Header';
import { Formik, Form, Field } from 'formik';
import { contact } from '../apis/contact';
import { LoaderContext } from '../context/LoaderContext';
import { PopUpContext } from '../context/PopUpContext';
import insta from "../assets/logo/instagram.png"
import linkedin from "../assets/logo/linkedin.png"
import twitter from "../assets/logo/twitter.png"
import mail from "../assets/logo/mail.png"
import LocalLoader from '../components/LocalLoader';

// import { Footer } from '../components/Contact/Footer'

export default function Contact() {



    const { popUpState, popUpContentState } = useContext(PopUpContext);
    const [popUp, setPopUp] = popUpState;
    const [popUpContent, setPopUpContent] = popUpContentState;

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {

        setLoading(true);
        const response = await contact(values);

        setLoading(false);

        if (response.status === 200) {
            setPopUpContent({ heading: "NOTICE", description: "The email has been sent!" });

        } else {
            setPopUpContent({ heading: "NOTICE", description: "Unable to send email!" });
        }
        setPopUp(true);

    }
    return (
        <div data-aos="fade-zoom-in" data-aos-duration="650" className='flex flex-col items-center bg-white'>
            <div className="w-screen m-auto text-white text-bold">
                <Header heading="Contact Us" id="contactuscomponent_id" />
            </div>
            {/* Contact form */}
            <div id="contactuscomponent_id" className='flex flex-col items-center w-full'>

                <div data-aos="fade-up" data-aos-duration="300" className='flex flex-col items-center w-full md:w-3/4  '>
                    {/* <h2>GET IN TOUCH</h2> */}
                    <div className='lg:m-24 px-2 md:px-6 w-full flex lg:flex-row flex-col py-4 md:space-x-16  '>

                        <Formik onSubmit={handleSubmit}
                            enableReinitialize={true}
                            initialValues={{
                                name: "",
                                email: "",
                                subject: "",
                                message: ""

                            }}
                            className='flex flex-col w-full'>
                            {(formProps) => (
                                <Form className='w-full'>
                                    <h1 className='text-2xl py-6'>GET IN TOUCH</h1>
                                    <h3 className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Name</h3>
                                    <Field id="name"
                                        required={true}
                                        name="name" className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type={"text"} placeholder="Name" />
                                    <h3 className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Email</h3>
                                    <Field id="email"
                                        required={true}
                                        type="email"

                                        name="email" className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' placeholder="Email" />
                                    <h3 className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Subject</h3>
                                    <Field id="subject"
                                        required={true}
                                        name="subject" className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type={"text"} placeholder="Subject" />
                                    <h3 className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Message</h3>
                                    <Field
                                        component="textarea"
                                        required={true}

                                        id="message" name="message" className='no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none' type={"text"} placeholder="Message" />

                                    <button disabled={loading ? true : false} class="shadow relative bg-dark hover:bg-gray-900  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-3 rounded min-w-[80px]" type="submit">
                                        {loading ?
                                            <LocalLoader small />
                                            :

                                            "Submit"
                                        }
                                    </button>


                                </Form>
                            )}

                        </Formik>
                        <div className='w-full px-5 md:px-0 md:w-64 py-16 lg:py-0 items-center block '>
                            <h1 className='text-2xl pb-6 '>GENERAL INFO</h1>

                            <p>TKM College of Engineering
                                Karicode, Peroor, Kollam, Kerala, 691005</p>
                            <p className='my-3 font-semibold'>iedc@tkmce.ac.in</p>
                            <div className='flex flex-row py-8 justify-between '>
                                <a href='https://www.instagram.com/iedc_tkmce/'>
                                    <img src={insta} className="w-6 h-6  "></img>
                                </a>
                                <a href='https://www.linkedin.com/company/iedc-tkmce/'>
                                    <img src={linkedin} className="w-6 h-6  "></img>
                                </a>
                                <a href='https://twitter.com/iedctkmce'>
                                    <img src={twitter} className="w-6 h-6 "></img>
                                </a>
                                <a href='mailto:iedc@tkmce.ac.in? subject=Enquiry through website'>
                                    <img src={mail} className="w-6 h-6 "></img>
                                </a>
                            </div>




                        </div>
                    </div>

                    <div className='hidden md:block lg:ml-10 pb-16 '>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.625473323738!2d76.62979256478586!3d8.914366493600463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05fd2f00000001%3A0xf40477ba854a425e!2sInnovation%20and%20Entrepreneurship%20Development%20Centre%2C%20TKMCE!5e0!3m2!1sen!2sin!4v1661570737417!5m2!1sen!2sin" width="1000" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>


                </div>

            </div>
            {/* <Footer/> */}
        </div>
    )
}

