import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
    const endTime = new Date('April 21, 2024 12:00:00').getTime(); // New end time set for 11:30 AM

    const calculateTimeLeft = () => {
        const difference = endTime - new Date().getTime();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor(difference / (1000 * 60 * 60)),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    // Building the display format hours:minutes:seconds
    const timeString = `${timeLeft.hours || '00'}:${timeLeft.minutes || '00'}:${timeLeft.seconds || '00'}`;

    return (
        <div>
            Time remaining 
            {Object.keys(timeLeft).length > 0 ? 
                <span className="text-4xl font-bold text-black">  {timeString}</span> : 
                <span className="text-4xl font-bold text-black">Time's up!</span>
            }
        </div>
    );
};

export default CountdownTimer;
