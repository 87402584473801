import { useState, useEffect, useContext } from 'react';
import { LoaderContext } from '../context/LoaderContext';

const useFetch = (url, execom_page) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;

  useEffect(() => {
    // setLoading(true);
    setIsPending(true);
    const abortCont = new AbortController();


    fetch(url, { signal: abortCont.signal })
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        }
        if (res.status === 204) {
          return { status: 204 }
        }
        return res.json();
      })
      .then(data => {
        // setIsPending(false);
        // setLoading(false);
        if (execom_page) {
          let sorted_data = data.sort((a, b) => { return a.year - b.year })
          return sorted_data;
        }
        return data;
      })
      .then(data => {
        // setIsPending(false);
        // setLoading(false);

        setData(data);
        setError(null);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          // setLoading(false);
        } else {
          // auto catches network / connection error
          // setLoading(false);
          // setIsPending(false);
          setError(err.message);
        }
      })
      .finally(() => {
        setIsPending(false);
      })

    // abort the fetch
    return () => abortCont.abort();
  }, [url, execom_page])

  return { data, isPending: isPending, error };
}

export default useFetch;