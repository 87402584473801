import React from 'react';

function NotFound(props) {
    return (
        <div data-aos="fade-zoom-in" data-aos-duration="650">
            
        </div>
    );
}

export default NotFound;