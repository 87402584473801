// import logo from "../assets/images/logo.png";

import { get_profile } from "../apis/user";



function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

async function gatewayResolver(response, token, setUserDetails, setPopUpContent, setPopUp, navigate, event_slug) {
  const responses = await displayRazorpay(
    response.amount,
    response.id,
    response.currency,
    response.client_name,
    response.email,
    response.phone,
    response.KEY,
    token,
    setUserDetails,
    setPopUpContent,
    setPopUp,
    navigate,
    event_slug
  )

  return responses;
}



async function displayRazorpay(
  amount,
  order_id,
  currency,
  username,
  email,
  contact,
  key,
  token,
  setUserDetails,
  setPopUpContent,
  setPopUp,
  navigate,
  event_slug
) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const options = {
    key: key, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "IEDC TKMCE",
    description: "",
    // TODO:
    // image: logo,
    order_id: order_id,
    handler: async function (response) {
      if (response.razorpay_order_id && response.razorpay_payment_id && response.razorpay_signature) {
        window.alert(`Payment successful \n Payment ID: ${response.razorpay_payment_id}`);
        if (event_slug === "membership-drive") {
          const profile_response = await get_profile(token);
          if (profile_response.status === 200) {
            if (profile_response.iedc_membership_status === true) {
              setUserDetails(profile_response.response.data);
              setPopUpContent({ heading: "NOTICE", description: "Membership registered" });
              setPopUp(true);
              navigate("/dashboard");
            } else {
              setPopUpContent({ heading: "NOTICE", description: "Something went wrong" });
              setPopUp(true);

            }
          } else {
            localStorage.setItem('IEDCUser', "");
            localStorage.setItem('IEDCUserToken', "");
          }
        } else {
          setPopUpContent({ heading: "NOTICE", description: "Registration successful" });
          setTimeout(() => {
            navigate("/events");
          }, 2000);
          setPopUp(true);
        }
      }
      // const data = {
      //   orderCreationId: order_id,
      //   razorpayPaymentId: response.razorpay_payment_id,
      //   razorpayOrderId: response.razorpay_order_id,
      //   razorpaySignature: response.razorpay_signature,
      // };
      // response.razorpay_payment_id
      //   ? window.location = 'https://www.hestiatkmce.live/payment/?success=true&session_id=' + response.razorpay_payment_id
      //   : window.location = 'https://www.hestiatkmce.live/payment/?canceled=true&session_id=' + response.razorpay_payment_id;

    },
    prefill: {
      name: username,
      email: email,
      contact: contact,
    },
    notes: {
      address: "None",
    },
    theme: {
      color: "#1b1b1b",
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}


export default gatewayResolver;
