import axios from "axios";
import { BASE_URL } from "../constants/urls";
export async function get_profile(token) {
    try {
        const response = await axios({
            method: 'get',
            url: BASE_URL + "/api/users/me",
            headers: { "Content-Type": "application/json", "Authorization": `token ${token}` },

        })
        return {
            status: response.status,
            response: response.data
        }
    } catch (e) {
        return {
            status: e.response ? e.response.status : e.status,
            errMssg: e.response.data
        }
    }
}
export async function edit_profile(token, data, user_name, email_id) {
    try {
        const response = await axios({
            method: 'put',
            url: BASE_URL + "/api/users/" + user_name + "/",
            headers: { "Content-Type": "application/json", "Authorization": `token ${token}` },
            data: {
                ...data,
                username: user_name.split(" ").join("_"),
                email_id: email_id,
                phone_number: "+91" + data.phone_number,
                profile_completed: true
            }
        })
        return {
            status: response.status,
            response: response.data
        }
    } catch (e) {
        return {
            status: e.response ? e.response.status : e.status,
            errMssg: e.response.data
        }
    }
}