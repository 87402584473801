export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const EVENT = "/events";
export const TEAM = "/team";
export const DARE2DEVELOP = "/dare2develop";
export const LOGIN = "/login";
export const REGISTRATIONFORM = "/registration_form";
export const PROFILE = "/profile";
export const STARTUPS = "/startups";
export const ECELL = "/ECell"
export const CONTACT = "/contact"
export const IRCELL = "/IRCell"
// export const EVENTDETAILS = "/event_details"
