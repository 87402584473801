import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './context/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoaderContextProvider } from './context/LoaderContext';
import { PopUpContextProvider } from './context/PopUpContext';

ReactDOM.render(
  <React.StrictMode>
    <PopUpContextProvider>
      <LoaderContextProvider>
        <ContextProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <App />
          </GoogleOAuthProvider>
        </ContextProvider>
      </LoaderContextProvider>
    </PopUpContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
