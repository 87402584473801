import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import * as animationData from "../../assets/lotties/fam.json";
export const DescCard = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-row bg-white md:h-[120vh] ">
      <div className="w-full lg:w-3/4 flex flex-col h-full">
        <div className="flex flex-row justify-end pt-11 w-full bg-white md:h-1/2 ">
          <div className="px-2 md:px-10 lg:px-0 w-full lg:w-[80%]">
            {/* <p className="uppercase mx-3 text-red-500 font-semibold text-md subHeading">WE AT IEDC TKMCE</p> */}
            <p className=" font-bold text-md font-gillory text-6xl lg:text-8xl w-4/5 lg:pt-7 mx-3 ">think.</p>
            <p className="font-bold text-md font-gillory text-6xl lg:text-8xl w-4/5 lg:pt-5 pb-5 mx-3 ">innovation.</p>
          </div>
        </div>
        <div className="w-full md:h-3/4 flex flex-row">
          <div className="hidden lg:block bg-white w-2/5">
            <div className="bg-dark h-3/4 w-full">
            </div>
          </div>
          <div className="bg-white w-full lg:w-3/5 h-3/5 flex flex-col items-center">
            <p className="text-lg font-medium w-full lg:w-3/4 text-left lg:text-justify px-5 md:px-9 ">
              The Innovation and Entrepreneurship Development Center of TKMCE is an organisation that aims to promote the institutional vision of transforming youngsters into technological entrepreneurs and innovative leaders. The initiative is to address the rising trend of corporate job culture and establish a platform to pursue ideas and businesses at an early stage.
            </p>
            <p className="text-lg font-medium w-full lg:w-3/4 mt-10 text-left lg:text-justify px-5 md:px-9">
              We at IEDC TKMCE host various workshops, speaker sessions, competitions to develop skill sets and provide essential resources to take up entrepreneurship as a career.
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block w-1/4 h-full flex flex-col">
        <div className="h-3/4 bg-dark w-full">
        </div>
        {/* <div className="h-1/5 bg-white w-full">
        </div> */}
      </div>
    </div>
  );
};
