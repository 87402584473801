import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_URL, S3_URL } from "../../constants/urls";
import useFetch from "../../hooks/useFetch";
import { EventCard } from "./EventCard";
import SwiperCore, { Autoplay } from 'swiper';
import { Parallax, Mousewheel, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";
import bg from "../../assets/logo/ctlines.jpg"


// import "./Swipper.css";


import NavBar from "../NavBar";
import useWindowSize from "../../hooks/useWindowSize";
import LocalLoader from "../LocalLoader";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { get_google_profile, login } from "../../apis/auth";
import { get_profile } from "../../apis/user";
import { useGoogleLogin } from "@react-oauth/google";
import { UserContext } from "../../context/UserContext";
import { PopUpContext } from "../../context/PopUpContext";
import { LoaderContext } from "../../context/LoaderContext";
gsap.registerPlugin(ScrollTrigger);


export const Hero = (props) => {

  const navigate = useNavigate();

  const [width, height] = useWindowSize();
  const [navBarShow, setNavBarShow] = useState(true);

  const { tokenState, userState } = useContext(UserContext);
  const [token, setToken] = tokenState;
  const [userDetails, setUserDetails] = userState;

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;

  const loginIEDC = async (credentialResponse, b) => {
    setLoading(true);
    const google_profile_response = await get_google_profile(credentialResponse.access_token);
    if (google_profile_response.status === 200) {
      const response = await login(credentialResponse.access_token);
      if (response.status === 200) {


        setToken(response.response.key);
        // setPopUpContent({ heading: "NOTICE", description: "Login successfull" })
        // setPopUp(true);
        const profile_response = await get_profile(response.response.key);
        // setUserDetails(google_profile_response)

        // let userObj={ full_name: google_profile_response.response.names[0].displayName, email_id: google_profile_response.response.emailAddresses[0].value, profile_pic: google_profile_response.response.photos[0].url,profile_completed:profile_response.response.profile_completed }
        // localStorage.setItem('IEDCUser', JSON.stringify(userObj))
        if (profile_response.status === 200) {

          localStorage.setItem('IEDCUserToken', response.response.key)
          setUserDetails(profile_response.response.data)


          if (profile_response.response.data.iedc_membership_status === false) {
            navigate("/dashboard/register_for_membership");
          } else {

            navigate("/dashboard/view_profile");
          }
        }

      } else {
        setPopUpContent({ heading: "Login failed", description: "Login failed." })
        setPopUp(true);
      }
    } else {
      setPopUpContent({ heading: "Login failed", description: "Login failed." })
      setPopUp(true);
    }
    setLoading(false);



  };


  const getGoogleAccount = useGoogleLogin({
    onSuccess: loginIEDC,
    redirect_uri: 'http://localhost:3000/',
    scope: 'https://www.googleapis.com/auth/userinfo.profile',
    onError: () => {
    },


  });

  useEffect(() => {
    if (width < 768)
      setNavBarShow(false);
    else
      setNavBarShow(true);

  }, [width]);
  SwiperCore.use([Autoplay])

  const rightTextRef = useRef();
  const descRef = useRef();
  const leftTextRef = useRef();
  const bgRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    (async () => {

      const e0 = await bgRef.current;
      const e1 = await rightTextRef.current;
      const e2 = await leftTextRef.current;
      const e3 = await descRef.current;
      const e4 = await buttonRef.current;

      gsap.from(e1, {
        x: '0%'
      })
      gsap.from(e2, {
        x: '0%'
      })
      gsap.from(e3, {
        opacity: '0.8'
      })
      gsap.from(e4, {
        opacity: '1'
      })
      gsap.to(e1, {
        scrollTrigger: {
          trigger: e0,
          scrub: true,
          start: "top 0px",
          end: "bottom 0px",
        },
        x: "100%",
        ease: "none",
      });
      gsap.to(e2, {
        scrollTrigger: {
          trigger: e0,
          scrub: true,
          start: "top 0px",
          end: "bottom 0px",
        },
        x: "-100%",
        ease: "none",
      });
      gsap.to(e3, {
        scrollTrigger: {
          trigger: e0,
          scrub: true,
          start: "top 0px",
          end: "bottom 85%",
        },
        opacity: "0",
        ease: "none",
      });
      gsap.to(e4, {
        scrollTrigger: {
          trigger: e0,
          scrub: true,
          start: "top 0px",
          end: "bottom 85%",
        },
        opacity: "0",
        ease: "ease-in-out",
      });

    })()
  }, [bgRef, rightTextRef, leftTextRef, buttonRef, descRef]);

  // console.log(userDetails)
  return (
    <header ref={bgRef} className="h-screen w-full selection-none flex flex-col text-white items-center justify-center relative bg-dark">
      <NavBar windowSize={{ width, height }} navBarShow={navBarShow} setNavBarShow={setNavBarShow} />
      <p className="text-5xl md:text-7xl lg:text-[125px] font-gillory " ref={leftTextRef}>we bring the</p>
      <p className="text-5xl md:text-7xl lg:text-[125px] font-gillory " ref={rightTextRef}>future closer.</p>
      <p ref={descRef} className="text-lg opacity-[0.8] md:text-3xl lg:text-4xl font-gillory mt-7 text-center px-3" >join the team of 4000+ amazing people where 40+ startups have registered!</p>
      {/* <div ref={buttonRef} className="flex  md:justify-center w-fit md:w-full md:px-28  mt-10">


        <Link to="/" className=" text-black font-gillory text-lg px-6 bg-white w-fit border rounded-full py-3 heading ">
          Join IEDC
        </Link>
      </div> */}
      <button onClick={() => { if (userDetails === null) { getGoogleAccount() } else { if (userDetails.iedc_membership_status === false) { navigate("/dashboard/register_for_membership") } else { navigate("/dashboard/view_profile") } } }} ref={buttonRef} className="flex md:justify-center w-fit font-gillory text-lg heading md:px-8 z-[10]  mt-10 rounded-full px-5 py-2.5 overflow-hidden group  bg-white relative  text-black hover:ring-1 hover:ring-offset-[0.05]  hover:ring-gray-200 transition-all ease-out duration-300">
        <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-gray-500 opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
        <span className="relative">Join IEDC</span>
      </button>
      <img className="w-full md:w-1/2 absolute -bottom-5 left-0 opacity-[0.15] z-[0]" src="/images/hero/hero_section_lineart.png" alt=" " />
    </header>
  );
};
