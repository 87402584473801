import React, { useState, useEffect } from 'react'
import { useContext } from 'react';
import { LoaderContext } from '../context/LoaderContext';
import Header from '../components/general/Header';
import StoryComponent from '../components/stories/StoryComponent';
import image from '../svgs/stories.jpeg'



const Stories = () => {


  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;


  // const filteredExecom = execomTeam

  return (
    <div className='w-full flex flex-col items-center mt-0 bg-white min-h-screen'>
      <div className="w-screen py-28 flex justify-center items-center relative mt-28">
        <p className='absolute text-6xl lg:text-9xl text-extrabold heading text-black '>Our<span className='text-blue-600 ml-5'>Stories</span> </p>
        {/* <div className='h-3/4'>
          <img alt="bg" src={image} className='h-full items-center z-1 ' />
        </div> */}

        {/* <Header heading={"STORIES"} id={"storycomponent_id"} /> */}
      </div>
      <div className='flex justify-center items-center w-full md:w-3/4 lg:w-1/2 pb-24'>
        <p data-aos-duration="300" data-aos="fade-in" className='text-bold text-2xl w-4/5 font-bold text-center'>Memories are to be framed when people with a single goal come under an umbrella. IEDC TKMCE holds the most beautiful smiles and minds in its journey of togetherness.
        </p>
      </div>

      <div id="storycomponent_id" className='w-full px-5 lg:px-0 lg:w-4/5 xl:w-3/5'>
        <StoryComponent />
      </div>


      {/* <StackGrid
        columnWidth={260}
        className="w-3/4 mt-10"
        
      >
        {stories !== null &&
          stories?.data.length>0 && stories.data.map((content, index) => (
              <StoryCard key={index} name={content.story_name} image={content.story_image} />
          ))
        }
      </StackGrid> */}
      <div className='grid grid-cols-4 w-3/4 mt-10 grid-flow-col'>

      </div>


    </div>
  )
}

export default Stories;
