import React from "react";
import { BASE_URL } from "../../constants/urls";
import useFetch from "../../hooks/useFetch";
import LocalLoader from "../LocalLoader";
import { QuestCard } from "./FreqQuesCard";

export const FreqQues = (props) => {

  const { error, isPending, data: faq } = useFetch(BASE_URL + '/contact-us/faq');
  return (
    <section className="bg-white ">
      <div className="container max-w-4xl px-6 py-10 mx-auto">
        <h1 data-aos-duration="300" data-aos="fade-in" className="text-5xl capitalize font-gillory text-center text-black font-bold">
          Frequently asked questions
        </h1>

        <div data-aos-duration="300" data-aos="fade-in" className="mt-12 space-y-8">
          {faq !== null && faq?.data && faq.data.length > 0 && faq.data.map((item, index) => (
            <QuestCard faq={item} />
          ))}
          {/* {faq !== null && faq?.data && <QuestCard faq={faq.data} />} */}
          {!error && faq?.data?.length === 0 && <div className='p-2 w-full text-center'>No question</div>}
          {error && <div className='p-2 w-full text-center'>{error}</div>}
          {isPending && <div className='p-2 w-full text-center relative'><LocalLoader /></div>}
        </div>
      </div>
    </section>
  );
};
