import React from 'react';
import { BASE_URL } from '../../constants/urls';

const StoryCard = ({ name, image }) => {
  return (
        <div className="w-full mb-28 group">
            <div className=" columns-2xs w-full text-gray-100 flex flex-col  cursor-pointer ">
                <img alt="" src={image} className="w-full  scale-100 group-hover:scale-95 ease-in duration-300" />
                <p className=" text-2xl font-semibold pl-2 pt-6 text-black">{name}</p>
            </div>
        </div>

    

  )
}

export default StoryCard;