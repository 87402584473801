import { useState, useEffect, useContext } from 'react';
import { LoaderContext } from '../context/LoaderContext';
import { UserContext } from '../context/UserContext';

const useAuthFetch = (url, refetch, certificate = false) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const { loadingState } = useContext(LoaderContext);
    const [loading, setLoading] = loadingState;
    const { tokenState } = useContext(UserContext);
    const [token, setToken] = tokenState;

    useEffect(() => {
        // setLoading(true);
        setIsPending(true);
        const abortCont = new AbortController();

        fetch(url, { signal: abortCont.signal, method: certificate === true ? "post" : "get", headers: { "Authorization": `token ${token}` } })
            .then(res => {
                if (!res.ok) { // error coming back from server
                    throw Error('could not fetch the data for that resource');
                }
                return res.json();
            })
            .then(data => {
                // setIsPending(false);
                // setLoading(false);
                setData(data);
                setError(null);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    // console.log('fetch aborted')
                } else {
                    // auto catches network / connection error
                    // setLoading(false);
                    // setIsPending(false);
                    setError(err.message);
                }
            })
            .finally(() => {
                setIsPending(false);
            })

        // abort the fetch
        return () => abortCont.abort();
    }, [url, token, refetch])

    return { data, isPending, error };
}

export default useAuthFetch;