import React from "react";

export const YearCard = ({ year, description }) => {
  return (
    <div className="renderAnimate max-w-lg lg:mx-32 lg:order-2 mx-8 py-7 text-left">
      <h1 className="text-xl md:text-3xl mb-2 font-medium tracking-wide flex items-start lg:text-4xl text-red-400">
        {year}
      </h1>
      <p className=" text-base text-black">
        {description}
      </p>
      {/* <div className="w-11/12 flex justify-end mt-3">
        <button className="text-xs font-bold border-2 border-black px-2 py-1">EXPLORE</button>
      </div> */}
    </div>
  );
};