export const branches = [
    { slug: "B.Arch", label: "Architecture" },
    { slug: "CH", label: "Chemical Engineering" },
    { slug: "CE", label: "Civil Engineering" },
    { slug: "CSE", label: "Computer Science & Engineering" },
    { slug: "EC", label: "Electronics and Communication Engineering" },
    { slug: "EEE", label: "Electrical & Electronics Engineering" },
    { slug: "MCA", label: "Masters of Computer Application " },
    { slug: "MECH", label: "Mechanical Engineering" },
    { slug: "ER", label: "Electrical & Computer Science Engineering" }

]
