import React, { useEffect, useState } from 'react'
// import { Footer } from "../../components/HomePage/Footer";
import bg from "../../assets/kraftnightbg2.png"

import useFetch from '../../hooks/useFetch';
import { BASE_URL } from '../../constants/urls';


import LocalLoader from '../../components/LocalLoader';
import TokenCard from './components/TokenCard';

import SwiperCore, { Autoplay } from 'swiper';
import { Parallax, Mousewheel, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import TeamCard from './components/TeamCard';
import CountdownTimer from './CountdownTimer'
const KraftNight = () => {
    const { error, isPending, data: elements } = useFetch(BASE_URL + '/live/pool/');
    const { error_, isPending_, data: teams } = useFetch(BASE_URL + '/live/team/');



    SwiperCore.use([Autoplay])

    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" style={{ backgroundImage: `url(${bg})` }} className='w-full bg-cover  flex flex-col bg-white min-h-screen bg-[#edebeb]'>
            <div className="w-full">

                <div className=' flex flex-col w-full items-center text-center md:text-left w-full'>


                    <div className='w-full flex h-screen'>
                        <div className='w-[70%] h-full flex flex-col items-start py-10 justify-start px-4'>
                            <div className='  flex justify-start w-full mb-10'>
                                <h1 className='text-3xl md:text-5xl font-bold mx-24'>Kraft Night</h1>
                                <CountdownTimer/>
                            </div>

                            <Swiper style={{
                                "--swiper-navigation-color": "rgba(255, 255, 255, 0.272)",
                                "--swiper-pagination-color": "rgba(255, 255, 255, 0.272)",
                                "--swiper-pagination-bullet-height": "5px",
                                "--swiper-pagination-bullet-width": "15px",
                                height: '75%',
                            }} parallax={true}
                                autoplay={{ delay: 6000, disableOnInteraction: false }}

                                slidesPerView={1}
                                // spaceBetween={30}
                                pagination={{
                                    // clickable: true,
                                    type: "fraction",
                                }}
                                navigation={true}
                                modules={[Parallax, Pagination, Navigation]} className=" w-full ">
                                {elements !== null && elements.map((item, index) => {

                                    return (
                                        item.name !== "starter" &&
                                        <SwiperSlide style={{ listStyle: "none", height: 'full', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }} key={index} className="mb-16 lg:px-20">
                                            <div className='w-full flex flex-col border border-gray-300 rounded-lg p-3 bg-gray-200 bg-opacity-50  bg-transparent border bg-blur-md rounded-xl w-full pb-10 shadow-xl'>
                                                <div style={{ backgroundColor: `${item.color}` }} className={`flex flex-row text-white justify-center items-center py-3 rounded-lg `}>

                                                    <p className='font-bold'>{item.credit_point}</p>
                                                    <p className='font-bold text-md ml-1'>{item.name} </p>
                                                </div>
                                                <div className='p-2 w-full gap-5 grid grid-cols-3'>
                                                    {item?.pools?.map((token, index) => (

                                                        <TokenCard key={index} {...token} color={item.color} />
                                                    ))}
                                                </div>

                                            </div>
                                        </SwiperSlide>)

                                })
                                }
                            </Swiper>


                            {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
                            {isPending && <div className='p-2 w-full text-center text-black relative'><LocalLoader /></div>}
                            {!error && elements?.length === 0 && <div className='p-2 w-full text-center text-black'>No pools</div>}


                        </div>
                        <div className='ml-12 w-[30%] flex flex-col pr-5 my-8 mr-12 border border-gray-100 rounded-lg p-3 bg-gray-200 bg-opacity-50'>
                            <div className='flex flex-row justify-between'>
                                <p className='font-bold text-xl ml-3'>Teams</p>
                                <p className='font-bold text-md mr-3'>Used</p>

                            </div>
                            {
                                teams !== null && teams.map((item, ind) => {
                                    return (
                                        <TeamCard key={ind} {...item} />
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default KraftNight
