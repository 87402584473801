
import React, { Component, useEffect } from 'react'
// import { Footer } from '../../components/StartupPage/Footer';
import StartupCard from '../../components/StartupPage/StartupCard';

import { get_startups } from '../../apis/startups';
import { useContext } from 'react';
import { LoaderContext } from '../../context/LoaderContext';
import { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { BASE_URL } from '../../constants/urls';
import Header from '../../components/general/Header';
import LocalLoader from '../../components/LocalLoader';

import plus from '../../svgs/plus-pattern.png'
import { createStringLiteralFromNode } from 'typescript';


const StartUps = () => {


  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;
  const { error, isPending, data: startup } = useFetch(BASE_URL + '/startup/startups');

  let years = [];
  startup !== null &&
    startup.forEach(element => {
      let year = parseInt(element.year);
      years.indexOf(year) === -1 && years.push(year)
    });

  let sorted_years = years.sort((a, b) => b - a)


  return (
    <div data-aos="fade-zoom-in" data-aos-duration="300" className='w-full bg-white min-h-screen flex flex-col items-center md:items-start justify-center md:justify-start overflow-hidden'>


      <div className='mt-28 mb-20 md:my-48  '>
        <h1 className='text-3xl md:text-7xl font-bold mx-5 md:mx-80'>Startups</h1>
        <img data-aos-duration="300" data-aos="fade-in" alt="" src={plus} class="absolute h-64 hidden lg:block right-0 top-0 mt-28" />

      </div>
      <div id="startupcomponent_id" class=" flex flex-col  mb-40 px-2 md:ml-20">

        {
          startup !== null &&
          sorted_years.map((year, ind) => {
            return (
              <div className='mb-20'>
                <p className='text-3xl font-semibold md:ml-20 text-red-500 underline mb-2 px-5'>{year}</p>
                {startup.map((content, index) => {
                  return parseInt(content.year) === year && (
                    <div>
                      <StartupCard key={index} startupname1={content.name} startupdesc1={content.stage_of_idea} startupimage1={content.image} />
                    </div>
                  )
                })}
              </div>
            )
          })
        }
      </div>
      {isPending && <div className='p-2 w-full text-black text-center relative'><LocalLoader /></div>}
      {error && <div className='p-2 w-full text-black text-center'>{error}</div>}
      {!error && startup?.length === 0 && <div className='p-2 w-full text-black text-center'>No startups.</div>}
      {/* <Footer /> */}
    </div>
  )
}

export default StartUps;


