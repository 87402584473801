import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate, Redirect, useLocation } from 'react-router-dom';

//components

//pages
import NotFound from './pages/NotFound';
import EventsPage from './pages/Events/EventsPage';
import ExecomPage from './pages/Execom/ExecomPage';
import Dashboard from './pages/Dashboard/Dashboard';
import EventView from './pages/Events/EventView';
import ECell from './pages/StartupPages/ECell';

import Contact from './pages/Contact'

//styles
import './App.css';
import { DASHBOARD, EVENT, HOME, LOGIN, EVENTDETAILS, STARTUPPAGE, REGISTRATIONFORM, PROFILE, EXECOM, STARTUPS, ECELL, CONTACT, IRCELL } from './constants/routes';
import IRCell from './pages/StartupPages/IRCell';
import { HomePage } from './pages/Home';
import { SidebarProvider } from './context/SidebarContext';
import Loader from './components/Loader';
import { LoaderContext } from './context/LoaderContext';
import { UserContext } from './context/UserContext';
import NavBar from './components/NavBar';
import StartUps from './pages/StartupPages/StartUps';
import useWindowSize from './hooks/useWindowSize';
import GeneralPage from './pages/general';
import { get_profile } from './apis/user';
import { PopUpContext } from './context/PopUpContext';
import { PopUp } from './components/PopUp';
import EditProfile from './pages/Dashboard/EditProfile';
import ThemedSuspense from './components/Dashboard/ThemedSuspense';
import AOS from 'aos';
import 'aos/dist/aos.css';



import "./assets/fonts/Swansea/style.css";
import "./assets/fonts/Skape/style.css";
import "./assets/fonts/Socicons/socicon.css";
import KraftNight from './live_events/kraft night';



const Layout = lazy(() => import('./containers/Layout'))

function RequireAuth() {
  const token = localStorage.getItem('IEDCUserToken');
  if (token?.length > 0)
    return <Outlet />
  else
    return <Navigate to="/" element={<HomePage />} exact />

};

function App() {
  AOS.init({ duration: 2000 });


  const { tokenState, userState } = useContext(UserContext);
  const { loadingState, preLoadingState } = useContext(LoaderContext);

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const [loading, setLoading] = loadingState;

  const [preLoading, setPreLoading] = preLoadingState;

  const [token, setToken] = tokenState;
  const [userDetails, setUserDetails] = userState;
  const [sideBarShow, setSideBarShow] = useState(false);
  const [width, height] = useWindowSize();



  useEffect(() => {
    (async () => {
      setLoading(true);
      setPopUp(false);
      if (width > 768) {
        setSideBarShow(true);
      }
      // const loggedInUser = localStorage.getItem('IEDCUser');
      const loggedInUserToken = localStorage.getItem('IEDCUserToken');
      try {
        if (loggedInUserToken.length > 0) {
          const response = await get_profile(loggedInUserToken);
          if (response.status === 200) {

            setUserDetails(response.response.data);
            setToken(loggedInUserToken);
            setPopUpContent({ heading: "NOTICE", description: "Login successful" });
            setPopUp(true);
          } else {
            localStorage.setItem('IEDCUser', "");
            localStorage.setItem('IEDCUserToken', "");
          }
        }
      }
      catch (e) {
        // setContent({title:"Info",desc:"Something went wrong.\nTry again later"});
        // setPopUp(true);
        // clearContent();
      }
      finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={`max-w-screen w-full flex bg-white overflow-x-hidden`}>
      <Loader loading={preLoading} />
      <PopUp />
      <BrowserRouter>
        <Suspense fallback={null} >
          <Routes>
            <Route path={HOME} element={<HomePage />} exact />
            <Route path={"/live/kraft_night"} element={<KraftNight />} exact />
            {/* <Route path={EVENTDETAILS} element={<EventView />} exact /> */}
            <Route path={"/:page"}>
              <Route path="" element={<GeneralPage />} />
              <Route path=":subPage/:slug" element={<GeneralPage />} />
              <Route path=":subPage/:slug/:sub" element={<GeneralPage />} />
            </Route>

            {/* <Route path={REGISTRATIONFORM} element={<RegistrationForm />} exact />
            <Route path={PROFILE} element={<Profile />} exact /> */}

            <Route element={<RequireAuth />}>
              <Route path='/dashboard/*' element={
                // <Suspense fallback={null}>
                <SidebarProvider>
                  <Layout sideBarShow={sideBarShow} setSideBarShow={setSideBarShow} />
                </SidebarProvider>
                // </Suspense>
              } />
            </Route>
            <Route path="*" exact={true} element={<NotFound />} />
          </Routes>
        </Suspense>



      </BrowserRouter>
    </div>
  );
}

export default App;
