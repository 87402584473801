import React from "react";
import { BASE_URL } from "../../constants/urls";
import useFetch from "../../hooks/useFetch";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";
import { Link } from "react-router-dom";

import LocalLoader from "../LocalLoader";
export const LatestEvents = (props) => {

  const { error, isPending, data: events } = useFetch(BASE_URL + '/events/all-events');


  const month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  return (
    <div className="py-16 md:py-0 md:h-screen w-full selection-none relative items-center flex flex-col items-center">
      <h1 data-aos="fade-in" data-aos-duration="300" className="text-black capitalize text-3xl md:text-5xl font-gillory text-center pt-8 mb-14 font-bold">
        Latest Events
      </h1>
      <div className="w-full px-2 md:px-0 md:w-3/5 flex flex-col items-center">
        {events !== null &&
          events.filter((item) => item.visible_in_front).slice(0, 3).map((event, index) => (
            event.visible_in_front &&
            <Link data-aos-duration="300" data-aos="fade-in" to={`/events/event_details/${event.slug}`} className="flex text-gray-400 hover:text-black flex-row border-t border-gray-400 py-4 md:py-8 items-center w-full px-3">
              {
                event.event_date !== null &&
                <div className='mr-5 flex flex-col items-center w-16'>
                  <p className='font-bold text-3xl md:text-5xl'>
                    {new Date(event.event_date).getDate()}
                  </p>
                  <p className='text-sm'>
                    {month[new Date(event.event_date).getMonth()]}
                  </p>
                </div>
              }

              <p className="text-xl md:text-3xl font-semibold w-4/5 text-left px-3 truncate">{event.event_name}</p>
              <p className="text-lg md:text-2xl font-semibold">{event.is_payment_id ? "₹" + event.event_fee : "Free"}</p>
            </Link>
          ))
        }

        {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
        {isPending && <div className='p-2 w-full text-center relative '><LocalLoader /></div>}
        {!error && events?.length === 0 && <div className='p-2 w-full text-center text-black'>No events</div>}
        {!error && events?.length !== 0 && !isPending && <Link to="/events" data-aos="fade-in" className='p-2 hover:text-red-400 font-bold text-base'>See more events</Link>}

      </div>

    </div>
  );
};