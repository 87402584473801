import React from 'react';
import { YearCard } from '../../components/Execom/YearCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Mousewheel, Pagination, Navigation } from "swiper";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lotties/scroll.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";
import "./Swipper.css";

function Header({ heading, team_page, error, execom, setCurrentYearIndex, id }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <header className='relative'>
            <div className="flex relative flex-col items-center justify-center mx-auto space-y-6 h-[90vh] bg-dark">

                <p className='text-3xl normal-case md:text-9xl z-10 w-full text-center w-full text-white font-normal font-gillory'>{heading}</p>

                {
                    team_page &&
                    <div className='w-full md:w-3/4 flex flex-col items-center absolute bottom-28 md:bottom-24'>
                        {error && <div className='p-2'>{error}</div>}

                        <div className='w-3/4 md:w-3/5 h-24 md:h-1/2 overflow-x-hidden p-2'>
                            <Swiper
                                style={{
                                    "--swiper-navigation-color": "rgba(255, 255, 255, 0.272)",
                                    "--swiper-pagination-color": "rgba(255, 255, 255, 0.272)",
                                    "--swiper-pagination-bullet-height": "3px",
                                    "--swiper-pagination-bullet-width": "4px",
                                }}
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}
                                // autoplay={false}
                                speed={2000}
                                parallax={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Parallax, Pagination, Navigation]}
                                className="mySwiper"

                                onSlideChange={(e) => setCurrentYearIndex(e.snapIndex)}
                            >
                                {execom !== null && execom.length > 0 &&
                                    execom.map((year, index) => (
                                        <SwiperSlide key={index}>
                                            <YearCard year={year.year} description={year.description} />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                        {/* <div className='w-full p-2 md:w-3/5 h-1/2  mt-10 '>
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#000",
                            "--swiper-pagination-color": "#000",
                            "--swiper-pagination-bullet-left": "0px",
                            "--swiper-pagination-bullet-height": "40px",
                            "--swiper-pagination-bullet-width": "10px",
                        }}
                        autoplay={true}
                        speed={600}
                        parallax={true}
                        pagination={{
                            clickable: true,
                        }}
                        direction={"horizontal"}
                        // navigation={true}
                        modules={[Parallax, Pagination, Navigation]}
                        className="mySwiper "
                    >
                        {execom !== null && execom.length>0 && 
                            execom[currentYearIndex]?.team.map((member, index) => (
                                <SwiperSlide key={index}>
                                    <Testimonial member={member} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div> */}
                    </div>
                }
                <div className="flex absolute items-bottom justify-center w-full bottom-20 md:bottom-10">
                    <div onClick={() => { if (id) document.getElementById(id).scrollIntoView({ behavior: "smooth" }) }}>

                        <Lottie
                            isClickToPauseDisabled={true}
                            options={defaultOptions}
                            height={50}
                            width={50}
                            isStopped={false}
                            isPaused={false}
                        />
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Header;