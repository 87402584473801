import React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function CircularProgressWithLabel(
    props
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress color='warning' variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function TeamCard({ name, balance = 100 }) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {

        setProgress(100 - balance);

    }, [balance]);

    return (
        <div className={` py-2 px-2 m-1 flex justify-between items-center border-b font-bold bg-gray-200 bg-opacity-30 bg-blur-lg shadow-xl rounded-xl border`}>
            <p className='truncate w-[80%] text-lg'>{name}</p>
            <CircularProgressWithLabel value={progress} />
        </div>

    );
}

export default TeamCard;