import React, { useContext, useState } from 'react'
import MetricCard from '../../components/Dashboard/MetricCard'
import Table from '../../components/Dashboard/Table'
import { Link } from "react-router-dom";
import { EventCard } from '../../components/Events/EventCard';
import { FaHome } from 'react-icons/fa'
import { Formik, Form, Field, useField } from 'formik';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../context/UserContext';
import useAuthFetch from '../../hooks/useAuthFetch';
import { BASE_URL } from '../../constants/urls';
import { edit_profile } from '../../apis/user';
import { PopUpContext } from '../../context/PopUpContext';
import { LoaderContext } from '../../context/LoaderContext';
import LocalLoader from '../../components/LocalLoader';
import { branches } from '../../constants/data';

function EditProfile() {

    const { userState, tokenState } = useContext(UserContext);
    const [userDetails, setUserDetails] = userState;
    const [token, setToken] = tokenState;
    const { popUpState, popUpContentState } = useContext(PopUpContext);
    const [popUp, setPopUp] = popUpState;
    const [popUpContent, setPopUpContent] = popUpContentState;

    const [loading, setLoading] = useState(false);

    const { error, isPending, data: profile } = useAuthFetch(BASE_URL + '/api/users/me');
    const navigate = useNavigate();

    // console.log(profile);
    const handleSubmit = async (values) => {

        setLoading(true);
        const response = await edit_profile(token, values, profile.data.username, profile.data.email_id);
        setLoading(false);
        setPopUp(true);
        if (response.status === 200) {
            setPopUpContent({ heading: "NOTICE", description: "Your profile has been updated successfully!" });
            // if (userDetails.iedc_membership_status === false) {
            //     setUserDetails(response.response);
            //     navigate("/dashboard/register_for_membership");
            // } else {

            //     setUserDetails(response.response);
            //     navigate("/dashboard");
            // }
            setUserDetails(response.response);
            navigate("/");
        } else {
            setLoading(false);
            // const keys = Object.keys(response.errMssg);
            // if (keys)
            alert(JSON.stringify(response.errMssg));
            setPopUpContent({ heading: "NOTICE", description: "Retry updating your profile!" })
        }
    };



    const year_range = (min, max) =>
        [...Array(max - min + 1).keys()].map((i) => i + min);


    return (
        <div data-aos="fade-zoom-in" data-aos-duration="1000" className='flex flex-row pb-24'>
            {
                loading &&
                <LocalLoader />
            }
            <div className='  rounded-md h-fit relative w-full  text-db-text-p flex flex-col overflow-x-hidden items-center text-md tracking-wide'>
                <h3 className='w-full my-5 text-left  ml-8  text-gray-500'>
                    <span className='text-gray-400'>User</span> / Edit profile</h3>
                <div className='  w-full md:w-2/3 h-full p-5 relative '>
                    <h3 className='text-db-text-h text-xl md:text-2xl text-center  '>{userDetails?.profile_completed === false ? "ADD PROFILE" : "EDIT PROFILE"}</h3>

                    {isPending && <div className='p-2'><LocalLoader /></div>}
                    {error && <div className='p-2'>{error}</div>}
                    <Formik onSubmit={handleSubmit}
                        enableReinitialize={true}
                        initialValues={{
                            admission_no: profile?.data?.admission_no !== null ? profile?.data?.admission_no : "",
                            name: profile?.data?.name !== null ? profile?.data?.name : "",
                            branch: profile?.data?.branch !== null ? profile?.data?.branch : "",
                            phone_number: profile?.data?.phone_number !== null && profile?.data.phone_number !== undefined ? profile?.data?.phone_number.slice(3) : "",
                            college: profile?.data?.college !== null ? profile?.data?.college : "",
                            course_duration_to: profile?.data?.course_duration_to !== null ? profile?.data?.course_duration_to : "",
                            course_duration_from: profile?.data?.course_duration_from !== null ? profile?.data?.course_duration_from : "",
                            bio: profile?.data?.bio !== null ? profile?.data?.bio : "",
                            why_iedc: profile?.data?.why_iedc !== null && profile?.data?.why_iedc !== undefined ? profile.data.why_iedc : ""

                        }}
                        className='flex flex-col w-full '>
                        {(formProps) => (
                            <Form>
                                <h3 className='mt-6 font-medium'>Admission Number<span className='text-red-400'>{'*'}</span></h3>
                                <Field id="admission_no"
                                    required={true}
                                    name="admission_no" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"number"} placeholder="" val />
                                <h3 className='mt-3 font-medium'>Name <span className='text-red-400'>{'*'}</span></h3>

                                <Field
                                    required={true}

                                    id="name" name="name" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="Name" />
                                <h3 className='mt-3 font-medium'>Branch<span className='text-red-400'>{'*'}</span></h3>
                                <Field
                                    required={true}
                                    as="select"
                                    id="branch" name="branch" className='bg-transparent outline-none  rounded-md py-3 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="Name">
                                    <option value=""></option>
                                    {branches.map((item, index) => (

                                        <option key={index} value={item.slug}>{item.label}</option>
                                    ))}
                                </Field>
                                <h3 className='mt-3 font-medium'>Phone number<span className='text-red-400'>{'*'}</span></h3>
                                <div className='bg-transparent flex flex-row items-center outline-none  rounded-md px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg'>
                                    <p>+91</p>
                                    <Field
                                        required={true} id="phone_number" name="phone_number" className='bg-transparent outline-none  rounded-md py-2 px-4 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"number"} placeholder="" />
                                </div>
                                <h3 className='mt-3 font-medium'>Bio</h3>
                                <Field
                                    id="bio" name="bio" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="" />
                                <h3 className='mt-3 font-medium'>Year<span className='text-red-400'>{'*'}</span></h3>
                                <div className='flex flex-row items-center'>
                                    <Field
                                        required={true}
                                        as="select"
                                        id="course_duration_from" name="course_duration_from" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="">
                                        {year_range(1900, 2030).map((item, ind) => {
                                            return (
                                                <option key={ind} value={item}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <h3 className='mx-2'>-</h3>
                                    <Field as="select"
                                        required={true} id="course_duration_to" name="course_duration_to" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="">
                                        {year_range(1900, 2030).map((item, ind) => {
                                            return (
                                                <option key={ind} value={item}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                </div>
                                <h3 className='mt-3 font-medium'>College<span className='text-red-400'>{'*'}</span></h3>
                                <Field
                                    required={true} id="college" name="college" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="" />
                                <h3 className='mt-3 font-medium'>Why iedc</h3>

                                {/* <MyTextArea


                                    required={true} id="why_iedc" name="why_iedc" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="" /> */}

                                <Field as="textarea" required={true} id="why_iedc" name="why_iedc" className='bg-transparent outline-none  rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border  bg-form-bg' type={"text"} placeholder="" />





                                <div className='mt-7 flex flex-row '>
                                    <button type="submit" className='text-black font-semibold hover:border-db-text-ph hover:text-white bg-gray-300 hover:bg-db-button-h rounded-md px-3 md:py-1 mr-5'>Submit</button>
                                    <button type="reset" onClick={Formik.resetForm} className='text-db-text-p  hover:border-db-text-ph hover:text-white bg-db-button hover:bg-db-button-h rounded-md px-3 md:py-1 '>Reset</button>


                                </div>

                            </Form>
                        )}



                    </Formik>
                </div>
            </div>

        </div>

    )
}

export default EditProfile;
