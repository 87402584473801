import React from 'react';
import arrow from '../../assets/logo/arrow.png';
const StartupCard = ({ startupname1, startupdesc1, startupimage1 }) => {
    return (

        <div className="  mt-2 flex flex-col md:flex-row w-full justify-center items-center md:items-start p-10 md:ml-20 ">
            <div className='md:w-1/5'>
                <img src={startupimage1} alt="" className="h-60 w-60 md:h-60 md:w-60  border-solid object-contain" data-aos="fade-in" />
            </div>
            <div className='md:pl-40 md:w-5/6 flex flex-col items-center md:items-start mt-8 md:pr-20' data-aos="fade-in">
                <h1 className="font-bold text-xl md:text-4xl mb-2 md:mb-5 md:pt-4 text-gray-800">
                    {startupname1}
                </h1>
                <p className="text-sm text-center  md:text-left text-gray-700">
                    {startupdesc1}
                </p>

            </div>

        </div>

    )
}
export default StartupCard;