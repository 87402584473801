import React from "react";
import { BASE_URL } from "../../constants/urls";
import useFetch from "../../hooks/useFetch";
import LocalLoader from "../LocalLoader";

export const NodalOfficers = (props) => {

  const { error, isPending, data: officers } = useFetch(BASE_URL + '/nodal_officer/officers');

  return (
    <section className="w-full">
      <div className="py-10 w-full">
        <h1 data-aos-duration="300" data-aos="fade-in" className="text-black capitalize text-5xl font-gillory text-left mb-8 w-full text-center font-bold">
          Our Nodal Officers
        </h1>

        {officers && officers.map((item, index) => (
          <div className="min-h-screen flex flex-col xl:flex-row w-full bg-dark xl:bg-transparent">
            <div className="w-full xl:w-2/6 h-full flex flex-col justify-center">
              <div className="w-full h-[40vh] lg:h-[60vh] bg-dark flex items-center justify-center">

                <img data-aos-duration="300" data-aos="fade-in" alt="officer" src={item.image} className="w-44 h-44 bg-dark object-contain" />
              </div>
            </div>
            <div className="w-full xl:w-4/6 bg-dark flex flex-col items-center pb-10 xl:pb-0">
              <div className="flex flex-col text-white w-full px-2 md:px-0 md:w-3/4 px-2 xl:px-0 xl:w-2/4 items-center xl:items-left justify-center h-full">
                <h1 data-aos-duration="300" data-aos="fade-in" className="text-3xl text-center xl:text-left md:text-5xl font-semibold  capitalize w-full">
                  {item.name}
                </h1>

                <p data-aos-duration="300" data-aos="fade-in" className="my-5 text-center xl:text-left w-full text-base md:text-xl">
                  {item.position}
                </p>
                <p data-aos-duration="300" data-aos="fade-in" className="text-base md:text-xl text-center xl:text-left text-gray-400">
                  {item.testimonial}
                </p>
              </div>
            </div>

          </div>
        ))}

        {!error && officers?.length === 0 && <div className='p-2 w-full text-center'>No officer</div>}
        {error && <div className='p-2 w-full text-center'>{error}</div>}
        {isPending && <div className='p-2 w-full text-center relative'><LocalLoader /></div>}


      </div>
    </section>
  );
};
