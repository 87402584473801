import React, { useContext, useEffect, useState } from 'react'
// import { Footer } from "../../components/HomePage/Footer";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { register_event } from '../../apis/register_event';
import LocalLoader from '../../components/LocalLoader';
import { BASE_URL } from '../../constants/urls';
import { LoaderContext } from '../../context/LoaderContext';
import { PopUpContext } from '../../context/PopUpContext';
import { UserContext } from '../../context/UserContext';
import useFetch from '../../hooks/useFetch';
import gatewayResolver from '../../utils/razorpayUtils';

import DownloadIcon from '@mui/icons-material/Download';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const EventView = ({ name, description, eventimage }) => {
    // const { state } = useLocation();
    const { slug } = useParams();

    const { error, isPending, data: event } = useFetch(BASE_URL + '/events/event/' + slug);

    //console.log(event.whatsapp_group_link ,"hello")
    
    const navigate = useNavigate();

    const { userState, tokenState } = useContext(UserContext);

    const [userDetails, setUserDetails] = userState;
    const [token, setToken] = tokenState;

    const { loadingState } = useContext(LoaderContext);

    const { popUpState, popUpContentState } = useContext(PopUpContext);
    const [popUp, setPopUp] = popUpState;
    const [popUpContent, setPopUpContent] = popUpContentState;

    const [loading, setLoading] = useState(false);
     
      const [resp,setResp] = useState(0); 

    const handleRegister = async (e) => {
        e.preventDefault();
        if (userDetails !== null && userDetails?.profile_completed === true) {
            if (event.is_team) {
                navigate(`/events/event_details/${slug}/create_team`);
            } else {
                setLoading(true);
                const response = await register_event(token,
                    {
                        title: "nice_team__333",
                        event_id: event.id,
                        team_leader_email: userDetails.email_id,
                        // members: [userDetails.email_id]
                    }
                );
                  setResp(response.status);
                setLoading(false);
                 
                
               
               
                if (response.status === 201) {
                    await gatewayResolver(response.status === 201 ? response.response : response.errMssg, token, setUserDetails, setPopUpContent, setPopUp, navigate, slug);
                    // setPopUpContent({ heading: "NOTICE", description: "Registration Successful" });
                    // setPopUp(true);
                } else if (response.status === 202 ) {
                    
                    setPopUpContent({ heading: "NOTICE", description: "Registration Successful" });
                    
                    setPopUp(true);
                } else if (response.status === 400 || response.status === 406) {
                    setPopUpContent({ heading: "NOTICE", description: response.errMssg?.message });
                    
                 setPopUp(true);

                }
                else if (response.status === 208) {
                    
                    setPopUpContent({ heading: "NOTICE", description: "Already Registered" });
                    
                    setPopUp(true);

                }
                else {
                    setPopUpContent({ heading: "NOTICE", description: "Something went wrong" })
                    setPopUp(true);

                }
                setLoading(false);
            }
        } else {
            if (userDetails === null) {
                setPopUpContent({ heading: "NOTICE", description: "You need to login before registration!" })
                setPopUp(true);
            } else {
                setPopUpContent({ heading: "NOTICE", description: "Complete your profile!" })
                setPopUp(true);
            }
        }
    }

    const { error2, isPending2, data: eventss } = useFetch(BASE_URL + '/events/event/' + slug);

    const handleDownloadGuideline = () => {
        try {
            if (!eventss.guideline_file || eventss.guideline_file === 'null') {
            } else {
                window.open(eventss.guideline_file, '_blank');
            }
        } catch (error) {
            console.error('Error opening guideline file:', error);
            window.alert('Error opening guideline file. Please try again later.');
        }
    };
    
    const renderWhatsAppButton = () => {
        if (event && event.whatsapp_group_link && (resp === 208 || resp.status === 202) ) {         //(resp.status=== 208 || resp.status === 202))
            console.log(event.whatsapp_group_link)
            return (
        
                <div className="flex items-center justify-center px-6 hover:text-gray-500 flex-grow md:flex-grow-0 cursor-pointer ">
                    <a href={`https://${event.whatsapp_group_link}`} target="_blank" rel="noopener noreferrer"  className="flex items-center gap-2">
                        <WhatsAppIcon />
                        WhatsApp Group
                    </a>
                </div>
            );
        }
        return null;
    };

    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" className="bg-primary w-screen m-auto flex justify-center mt-14">
            {event !== null &&
                <div className='flex min-h-screen flex-col items-start w-full mx-2 md:mx-0 md:w-3/4 xl:w-1/2 my-5 py-10  p-3 md:p-10 shadow-lg'>
                    <div className="flex flex-col relative items-start justify-center w-full">
                        {/* <img alt="" src={event?.image} className="absolute top-0 left-0 object-cover w-full h-full z-0 blur-sm opacity-[0.9]" /> */}
                        <img
                            className=" w-full md:w-2/3  z-[2]"
                            src={event?.image}
                            alt="pic"
                        />
                        <p className='text-xl mt-10 font-bold md:text-5xl header  w-full '>
                            {event?.event_name}
                        </p>
                    </div>
                    {/* <img src={event?.image} alt='event' className='w-2/5' /> */}
                    <div className=' flex flex-col md:items-start w-full                                                                       '>
                        <p className='font-normal text-left my-5 text-md md:text-base text-gray-600 '>
                            {event?.description}
                        </p>
                        <div className="flex flex-col items-start ">
                            <p className=' text-left font-bold mt-6 text-md md:text-lg'>
                                Prize
                            </p>
                            <p>₹{event?.prize}</p>
                        </div>
                        <div className="w-full flex justify-between mt-6 ">
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">Start date:</p>
                                <p className='font-normal text-left mb-2'>
                                    {new Date(event?.event_start).toISOString().slice(0, 10).split("-").reverse().join("-")}
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">End date:</p>
                                <p className='font-normal text-left mb-2'>
                                    {new Date(event?.event_end).toISOString().slice(0, 10).split("-").reverse().join("-")}
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">Venue:</p>
                                <p className='font-normal text-left mb-2'>
                                    {event?.event_venue}
                                </p>
                            </div>
                        </div>

                        <p className='font-normal text-left mb-5'>
                        </p>
                        {event?.is_team &&
                            <p className='font-bold text-left mb-5 '>
                                Team event {event?.min_members_in_team !== event?.max_members_in_team ? `(${event?.min_members_in_team} - ${event?.max_members_in_team})` : `(${event?.min_members_in_team})`}
                            </p>
                        }
                        <p className="uppercase text-lg font-semibold md:font-bold underline my-6">Registration Details</p>

                        <div className="w-full flex justify-between ">
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">Start date:</p>
                                <p className='font-normal text-left mb-2'>
                                    {new Date(event?.reg_start).toISOString().slice(0, 10).split("-").reverse().join("-")}
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">End date:</p>
                                <p className='font-normal text-left mb-2'>
                                    {new Date(event?.reg_end).toISOString().slice(0, 10).split("-").reverse().join("-")}
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">Fees:</p>
                                <p className='font-normal text-left mb-2'>
                                    ₹{event?.event_fee}
                                </p>
                            </div>
                        </div>
                        {(event?.coordinator_1?.name || event?.coordinator_2?.name) && <p className="uppercase text-md md:text-lg font-bold underline my-6">Coordinators</p>}
                        <div className="w-full flex justify-between ">
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">{event?.coordinator_1?.name}</p>
                                <p className='font-normal text-left mb-2'>
                                    {event?.coordinator_1?.phone_number}
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="font-bold text-base">{event?.coordinator_2?.name}</p>
                                <p className='font-normal text-left mb-2'>
                                    {event?.coordinator_2?.phone_number}
                                </p>
                            </div>
                        </div>
                        {/* <p className='font-normal text-left mb-2'>
                        {`${event?.cordinator_1_name}- +91${event?.cordinator_1_num}`}
                    </p>
                    <p className='font-normal text-left mb-2'>
                        {`${event?.cordinator_2_name}- +91${event?.cordinator_2_num}`}
                    </p> */}

                    <div className='flex flex-col md:flex-row md:gap-5 items-center justify-center'>
                            <button
                                // to={`/events/event_details/${props.slug}`}
                                disabled={loading ? true : false}
                                onClick={handleRegister}
                                className={`${loading ? "bg-white" : "bg-black"} px-14 py-3 heading min-h-[53px]  text-white font-bold text-lg my-5 transition-all relative flex-grow md:flex-grow-0'`}
                            >

                                {loading ? <LocalLoader small /> : "Register"}

                            </button>
                            <div
                                className="flex items-center justify-center px-6 hover:text-gray-500 flex-grow md:flex-grow-0' cursor-pointer"
                                >
                                    <DownloadIcon onClick={handleDownloadGuideline}/>
                                    <span className='hover:text-gray-500'>Guidelines</span>
                            </div>
                            {renderWhatsAppButton()}
                        </div>
                    </div>

                </div>
            }
            {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
            {isPending && <div className='p-2 mt-10 w-full text-center text-black relative'>< LocalLoader /></div>}
        </div>
    )
}

export default EventView