import React from 'react';

function TokenCard(props) {
    return (
        <div style={{
            borderBottom: `1px solid ${props.color}`
        }} className={` p-2 m-1 flex justify-between font-bold ${props.count === 0 && "line-through"}`}>
            <p className=''>{props.name}</p>
            <p className=''>{props.count}</p>
        </div>
    );
}

export default TokenCard;