import React from 'react';
import { AiFillLinkedin, AiOutlineLink } from 'react-icons/ai';

const ExecomCard = ({ e }) => {
  
  return (
    <div className=" w-52 relative">
      <div className="w-full  h-72 xl:h-56 relative group">
        <img
          className="object-cover w-full h-full z-[-1]"
          src={e.image}
          alt=""
        />
        {(e.linkedin_url || e.portfolio_url) &&

          <div className="duration-200 h-0 group-hover:h-14 absolute bottom-0 left-0 w-full bg-border z-10 flex flex-row items-center justify-center">
            {e.linkedin_url?.length > 0 &&
              <a
                href={e.linkedin_url}
                target="_blank"
                rel="noreferrer"
                className="mx-2 text-white className hover:text-black duration-300"
                aria-label="LinkedIn"
              >
                <AiFillLinkedin className="text-2xl opacity-0 group-hover:opacity-100 duration-300" />
              </a>
            }
            {e.portfolio_url?.length > 0 &&
              <a
                href={e.portfolio_url}
                target="_blank"
                rel="noreferrer"
                className="mx-2 text-white className hover:text-black duration-300"
                aria-label="Github"
              >
                <AiOutlineLink className="text-2xl opacity-0 group-hover:opacity-100 duration-300" />
              </a>
            }
          </div>
        }
      </div>


      <h1 className="mt-4 text-xl font-semibold text-black capitalize text-center">
        {e.name}
      </h1>

      <p className="mt-2 text-gray-800 capitalize font-semibold text-center">
        {e.position}
      </p>

      <div className="flex mt-3 -mx-2">





      </div>
    </div>
  )
}

export default ExecomCard
