import React from "react";
import { Link } from "react-router-dom";
import Instaicon from '@material-ui/icons/Instagram'
import Twittericon from '@material-ui/icons/Twitter'
import Fbicon from '@material-ui/icons/Facebook'
import Linkedinicon from '@material-ui/icons/LinkedIn'

export const Footer = (props) => {

  var today = new Date();
  var year = today.getFullYear();

  return (
    <div className="flex flex-col w-full">
      <footer className="bg-dark text-white shadow-lg w-full relative shadow-xl shadow-white pt-5 ">
        <div className="container px-6 py-4 mx-auto z-[10]">
          <div className="lg:flex w-full">
            <div className="w-full -mx-6 lg:w-2/5">
              <div className="px-6">
                <div>
                  <a
                    href="#"
                    className="text-xl font-bold  hover:text-gray-300"
                  >
                    IEDC TKMCE
                  </a>
                </div>

                <p className="max-w-md mt-2  text-justify lg:mr-6 mr-px hidden md:block">
                  The Innovation and Entrepreneurship Development Cell of TKMCE is an organisation that aims to promote the
                  institutional vision of transforming youngsters into technological entrepreneurs and innovative leaders.
                </p>

                <div className="flex w-fit  mt-4 -mx-2">
                  <a
                    href="https://www.linkedin.com/company/iedc-tkmce/?originalSubdomain=in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2  dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="Linkden"
                  >
                    {/* <Linkedinicon /> */}
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                      <path d="M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z" />
                    </svg>
                  </a>

                  <a
                    href="https://www.facebook.com/tkmiedc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2  dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="Facebook"
                  >
                    {/* <Fbicon /> */}
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                      <path d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z" />
                    </svg>
                  </a>
                  {/* <a
                    href="https://www.instagram.com/iedc_tkmce/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2   dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="Instagram"
                  >
                     <Instaicon className="" />
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                      <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                    </svg> 
                  </a> */}
                  <a
                    href="https://twitter.com/iedctkmce/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2  dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="Twitter"
                  >
                    {/* <Twittericon /> */}
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                      <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                    </svg>
                  </a>

                  <a
                    href="https://instagram.com/iedc_tkmce/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2  dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="Instagram"
                  >
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" >
                      <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z" />
                    </svg>
                  </a>
                  <a
                    href=" https://wa.me/+919207408109"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2  dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                    aria-label="WhatsApp"
                  >
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path fillRule="nonzero" d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-6 lg:mt-0 lg:flex-1">
              <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                <div>
                  <h3 className="font-bold uppercase ">About</h3>
                  <a
                    href="#"
                    className="block mt-2 text-sm hover:underline"
                  >
                    About Us
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm  hover:underline"
                  >
                    Vision
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm hover:underline"
                  >
                    Mission
                  </a>
                </div>

                <div>
                  <h3 className="font-bold uppercase ">Communitites</h3>
                  <Link
                    to='/stories'
                    className="block mt-2 text-sm hover:underline"
                  >
                    Stories
                  </Link>
                  <Link
                    to="/startups"
                    className="block mt-2 text-sm hover:underline"
                  >
                    E-Cell
                  </Link>
                  <a
                    href="#"
                    className="block mt-2 text-sm hover:underline"
                  >
                    IR Cell
                  </a>
                </div>

                <div>
                  <h3 className="font-bold uppercase ">Team</h3>
                  <Link
                    to="/team"
                    className="block mt-2 text-sm hover:underline"
                  >
                    Execom
                  </Link>
                  <Link
                    to="/team"
                    className="block mt-2 text-sm hover:underline"
                  >
                    Web team
                  </Link>

                </div>

                <div>
                  <h3 className="font-bold uppercase ">Contact</h3>

                  <span className="block mt-2 text-sm hover:underline">
                    iedc@tkmce.ac.in
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-between my-5">
            <Link className="hover:underline " to={"/terms_and_conditions"}>
              Terms and conditions
            </Link>
            <Link className="hover:underline " to={"/privacy_policy"}>
              Privacy policy
            </Link>
            <Link className="hover:underline " to={"/refund_policy"}>
              Refund policy
            </Link>
            <Link className="hover:underline " to={"/pricing"}>
              Pricing
            </Link>
          </div>
          <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" />

          <div className="w-full flex flex-col items-center">
            <p className="text-center text-gray-200 ">
              © IEDC TKMCE {year} - All rights reserved
            </p>

          </div>

        </div>
        {/* <img src="/images/footer/footer-shape-1.png" className="absolute bottom-0 left-0 z-[0]" /> */}
      </footer>
    </div>
  );
};
