import React, { useEffect } from "react";
import { ContactUs } from "../components/HomePage/ContactUs";
import { DescCard } from "../components/HomePage/DescCard";
import { Feature } from "../components/HomePage/Feature";
import { Footer } from "../components/Footer";
import { Hero } from "../components/HomePage/Hero";
import { FreqQues } from "../components/HomePage/Question";
import { Service } from "../components/HomePage/Service";
import { Team } from "../components/HomePage/Team";
import { NodalOfficers } from "../components/HomePage/NodalOfficers";
import NavBar from "../components/NavBar";
import useWindowSize from "../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { LatestEvents } from "../components/HomePage/LatestEvents";
import WorkedWith from "../components/HomePage/WorkedWith";
import Testimonial from "../components/HomePage/Testimonial";

export const HomePage = (props) => {


  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <div
      className={`bg-white w-full max-w-screen `}
    >
      <Hero />
      <DescCard />
      <WorkedWith />
      <Feature />
      <LatestEvents />
      <NodalOfficers />
      <Testimonial />
      {/* <Team /> */}
      <Service />

      <FreqQues />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  );
};
