import React from "react";
import "./story.css";

import { BASE_URL } from "../../constants/urls";
import useFetch from "../../hooks/useFetch";
import StoryCard from "./StoryCard";
import LocalLoader from "../LocalLoader";

// Masory Options


export default function StoryComponent() {


  const { error, isPending, data: stories } = useFetch(BASE_URL + '/gallery');

  return (
    <div className="w-full flex flex-wrap items-start justify-between">
      {/* <InfiniteScroll
        dataLength={imagesData.length}
        next={fetchData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      > */}
      {/* <Masony
          className={"photo-list w-full px-5 "}
          elementType={"ul"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        > */}
      {stories !== null &&
        stories?.data.map((story, index) => (
          <li data-aos="fade-in" className={`photo-item w-full md:w-[43%]`}>
            <StoryCard key={index} name={story.story_name} image={story.story_image} />

          </li>
        ))}
      {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
      {isPending && <div className='p-2 w-full text-center text-black relative'><LocalLoader /></div>}
      {!error && (stories?.data.length === 0) && <div className='p-2 w-full text-center text-black'>No story.</div>}
      {/* </Masony> */}
      {/* </InfiniteScroll> */}

      {/* <div
        id="myModal"
        className="modal"
        style={{
          display: imageModal.showModal ? "block" : "none"
        }}
      >
        <div>
          <span
            className="close"
            onClick={() =>
              setImageModal((modal) => ({ ...modal, showModal: false }))
            }ohhName="next" onClick={() => onSet("next")}>
            &#10095;
          </a>

          <div />
        </div>
      </div> */}
    </div>
  );
}
