
import React, { Component, useEffect } from 'react'
// import { Footer } from '../../components/StartupPage/Footer';



const RefundPolicy = () => {


    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" className='w-full bg-white min-h-screen flex flex-col items-center md:items-start justify-center md:justify-start overflow-hidden'>


            <div className='mt-28 md:mt-48  '>
                <h1 className='text-3xl md:text-3xl font-bold mx-5 md:mx-20 mb-10'>Refund Policy</h1>
            </div>
            <div className=" flex flex-col  mb-40 px-2 md:mx-20">

                <p className='capitalize'>
                    THANKS FOR REGISTERING TO IEDCTKMCE IF YOU ARE NOT ENTIRELY SATISFIED WITH YOUR PURCHASE, WE'RE HERE TO HELP. REFUND YOU HAVE 2​ HOURS AFTER REGISTRATION TO CANCEL YOUR REGISTRATION. TO BE ELIGIBLE FOR A REFUND, EITHER YOU SHOULD BE AN INDIVIDUAL REGISTRATION OR ALL YOUR TEAM MEMBERS SHOULD ALSO BE READY TO CANCEL THEIR REGISTRATION YOU SHOULD RETURN YOUR GOODIES TO BECOME ELIGIBLE FOR REFUND. CONTACT US IF YOU HAVE ANY QUESTIONS ON HOW TO RETURN YOUR ITEM TO US, CONTACT US. IEDC@TKMCE.AC.IN.COM  </p>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default RefundPolicy;


