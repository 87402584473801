import React, { useState } from "react";

export const QuestCard = (props) => {
  const [active, setActive] = useState();
  return (
    <div className="border-2 border-gray-100 rounded-lg ">
      <button onClick={(e) => {
        setActive(!active);
      }}
        className="flex items-center justify-between w-full p-8">
        <h1 className="font-semibold text-gray-700 text-left">
          {props.faq.question}
        </h1>

        <div
          className={`text-gray-400 ${!active ? " bg-black " : " bg-gray-200 "
            } rounded-full`}
        >
          {active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6  "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 12H6"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 [color:#fff]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          )}
        </div>
      </button>

      <hr className="border-gray-200 " />
      {active && (
        <p className="p-8 text-sm text-gray-500">
          {props.faq.answer}
        </p>
      )}
    </div>
  );
};
