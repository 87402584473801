import React from "react";
import makerlibrary from "../../assets/logo/makerlibrary.png"
import project from "../../assets/logo/presentation.png"
import preInc from "../../assets/logo/preinc.png"
import schedule from "../../assets/logo/schedule.png"
import model from "../../assets/logo/bmodel.png"
import server from "../../assets/logo/server.png"

export const Service = (props) => {
  return (
    <section className="bg-white md:min-h-screen flex items-center">
      <div className="container px-6 py-10 mx-auto">
        <h1 data-aos-duration="300" data-aos="fade-in" className="text-black capitalize text-5xl font-gillory text-left mb-8 w-full text-center font-bold">
          What we do
        </h1>



        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3 md:px-14 xl:px-36">
          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={makerlibrary} className="w-20 h-20"></img>

            <h1 className="text-xl font-semibold capitalize !:text-white">
              Maker Library
            </h1>

            <p className="text-gray-700 text-center">
              Book your componets virtually
            </p>
          </div>

          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={project} className="w-20 h-20"></img>


            <h1 className="text-xl font-semibold capitalize !:text-white">
              Project Proposals
            </h1>

            <p className="text-gray-700 !:text-gray-300 text-center">
              Submit your project proposal. We will help you to build your dream projects,
            </p>

            {/* <a
              href="#"
              className="inline-flex items-center -mx-1 text-sm text-border capitalize transition-colors duration-200 transform !:text-blue-400 hover:underline hover:text-blue-600 !:hover:text-border"
            >
              <span className="mx-1">read more</span>
              <svg
                className="w-4 h-4 mx-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a> */}
          </div>

          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={preInc} className="w-20 h-20"></img>


            <h1 className="text-xl font-semibold  capitalize !:text-white">
              Pre-Incubation
            </h1>

            <p className="text-gray-700 !:text-gray-300 text-center">
              Start on the right path with expert opinion.
            </p>


          </div>

          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={server} className="w-20 h-20"></img>


            <h1 className="text-xl font-semibold capitalize !:text-white">
              Server Access
            </h1>

            <p className="text-gray-700 !:text-gray-300 text-center">
              Host your website and boost your startups and projects.
            </p>


          </div>

          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={schedule} className="w-20 h-20"></img>

            <h1 className="text-xl font-semibold capitalize !:text-white">
              Schedule Meetings
            </h1>

            <p className="text-gray-700 !:text-gray-300 text-center">
              Digitalise all your meetings with a personalised calender
            </p>


          </div>

          <div data-aos="fade-in" data-aos-duration="300" className="px-4 space-y-3 group border border-gray-400 flex flex-col items-center justify-center h-[350px] hover:border-gray-800">
            <img src={model} className="w-20 h-20"></img>


            <h1 className="text-xl font-semibold  capitalize !:text-white">
              Business Models
            </h1>

            <p className="text-gray-700 !:text-gray-300 text-center">
              Submit your model for evaluation from our expert mentor pool.
            </p>


          </div>
        </div>
      </div>
    </section>
  );
};
