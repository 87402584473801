import React, { useState } from 'react'
import { useParams } from 'react-router-dom';


import LocalLoader from '../components/LocalLoader';
import { BASE_URL } from '../constants/urls';
import useFetch from '../hooks/useFetch';
const Verify = () => {
    const { slug } = useParams();

    const { error, isPending, data: details } = useFetch(BASE_URL + '/certificate/verify/' + slug);


    return (
        <div data-aos="fade-zoom-in" data-aos-duration="300" className="bg-primary w-screen m-auto flex justify-center mt-14">
            {details !== null && details?.status !== 204 &&
                <div className='flex flex-col items-start w-full mx-2 md:mx-0 md:w-3/4 xl:w-1/2 my-5 py-10  p-3 md:p-10 shadow-lg'>
                    <div className="flex flex-col relative items-start justify-center w-full">

                        <p className='text-sm mt-10 font-bold md:text-lg header  w-full '>
                            {details?.message}
                        </p>
                        <p className='text-sm mt-10 font-bold md:text-lg header  w-full opacity-[80%] '>
                            Certificate id : {details?.certificate_no}
                        </p>
                        <p className='text-sm mt-10 font-bold md:text-lg header  w-full opacity-[80%] '>
                            Participant name : {details?.participant_name[0]?.toUpperCase() + details?.participant_name?.slice(1)}
                        </p>
                        <p className='text-sm mt-2 font-bold md:text-lg header  w-full opacity-[80%] '>
                            Event name : {details?.event_name}
                        </p>
                    </div>
                    <div className=' flex flex-col md:items-start w-full                                                                       '>
                    </div>

                </div>
            }
            {error && <div className='p-2 w-full text-center text-black'>{error}</div>}
            {details !== null && details?.status === 204 && <p className='p-2 w-full text-center text-black mt-10 font-bold'>Certificate not found</p>}
            {isPending && <div className='p-2 w-full text-center text-black relative'>< LocalLoader /></div>}
        </div>
    )
}

export default Verify;