import React from 'react';

const ExecomDetails = () => {

  const execomPositions = [
    {
      title: 'Student Lead I and Student Lead II',
      description:
        'Should be from different departments and at least one should be a female. Represent the student body and voice concerns to the IEDC. Foster a positive and inclusive environment for all members. Coordinate student activities and events. Act as a liaison between students and other leadership roles.',
    },
    {
      title: 'Technology Lead',
      description:
        'Oversee the technological infrastructure of the organization. Identify and implement innovative technologies to enhance efficiency. Manage the organization\'s website, software, and other tech tools. Provide technical support to other teams and members.',
    },
    {
      title: 'Quality & Operations Lead',
      description:
        'Ensure the smooth functioning of day-to-day operations. Implement and improve processes to enhance efficiency. Monitor and maintain the quality of products or services. Collaborate with other leads to streamline cross-functional processes.',
    },
    {
      title: 'Finance Lead',
      description:
        'Manage the organization\'s budget and financial resources. Oversee financial transactions, accounting, and reporting. Provide financial guidance to other leads for budgeting their activities. Identify opportunities for cost-saving and revenue generation.',
    },
    {
      title: 'Creative & Innovation Lead',
      description:
        'Drive creative initiatives and innovative projects. Foster a culture of creativity and out-of-the-box thinking. Collaborate with other leads to infuse creativity into various aspects of the organization. Oversee the development of creative content and materials.',
    },
    {
      title: 'Branding & Marketing Lead',
      description:
        'Develop and execute marketing strategies to promote the organization. Manage branding efforts to enhance the organization\'s image. Coordinate promotional events and campaigns. Work with other leads to ensure consistent messaging across all channels.',
    },
    {
      title: 'Community Lead',
      description:
        'Build and nurture a sense of community within the organization. Organize community-building events and activities. Address the needs and concerns of the organization\'s members. Foster collaboration and networking among members.',
    },
    {
      title: 'Women Entrepreneurship Lead',
      description:
        'Promote and support women entrepreneurs within the organization. Develop initiatives to empower women in entrepreneurship. Organize events, workshops, and mentorship programs focused on women\'s leadership. Advocate for gender equality and inclusivity within the organization.',
    },
    {
      title: 'IPR & Research Lead',
      description:
        'Oversee intellectual property matters and research activities. Ensure compliance with intellectual property laws and regulations. Facilitate research projects and collaborations. Support innovation and protect the organization\'s intellectual assets.',
    },
    {
      title: 'Entrepreneurship Lead',
      description:
        'Entrepreneurship Lead will be responsible for overseeing the Entrepreneurship activities of TKM College of Engineering. The role will involve building relationships with student startups, identifying and organizing events to promote entrepreneurship, and collaborating with other departments and organizations.',
    },
  ];
  
  const steps = [
    {
      title: 'Announcement of Election',
      content:
        'Announce the opening of student lead positions in the IEDC through various communication channels, including posters, emails, and social media. Clearly outline the eligibility criteria, which may include a minimum GPA, a demonstrated interest in innovation and entrepreneurship, and active participation in related activities.',
    },
    {
      title: 'Nomination Submission',
      content:
        'Establish a dedicated platform for the submission of nominations. Nominees should provide a comprehensive statement of interest highlighting their motivation, relevant experiences, and a vision for promoting innovation and entrepreneurship within the IEDC.',
    },
    {
      title: 'Initial Review',
      content:
        'Form a screening committee to conduct an initial review of all applications for completeness and adherence to guidelines. Identify and address any discrepancies or missing information through direct communication with the candidates.',
    },
    {
      title: 'Shortlisting',
      content:
        'Evaluate applications based on criteria such as the candidate\'s passion for innovation, demonstrated leadership, and alignment with the IEDC\'s mission. Shortlist candidates who exhibit strong potential for contributing to the IEDC\'s goals.',
    },
    {
      title: 'Panel Interview',
      content:
        'Organize panel interviews involving representatives from the IEDC, faculty members, and external experts. Structure interviews to assess candidates\' understanding of innovation, their entrepreneurial mindset, and their ability to work collaboratively.',
    },
    {
      title: 'Case Study or Presentation',
      content:
        'Introduce a case study or presentation component to evaluate candidates\' analytical and communication skills. Topics may include proposing an innovative project, addressing a real-world entrepreneurial challenge, or presenting their vision for the IEDC.',
    },
    {
      title: 'Evaluation and Scoring',
      content:
        'Establish a scoring system to evaluate candidates based on their interview performance, application materials, and references. Consider factors such as innovation mindset, leadership potential, and communication skills in the evaluation process.',
    },
    {
      title: 'Selection Committee Meeting',
      content:
        'Convene a meeting with the selection committee to discuss and finalize student lead selections. Ensure thorough deliberation and consensus-building among committee members.',
    },
    {
      title: 'Announcement',
      content:
        'Announce the selected student leads to the IEDC community through a formal communication channel. Acknowledge the efforts of all participants and express gratitude for their interest in contributing to the IEDC.',
    },
    {
      title: 'Transition Period',
      content:
        'Facilitate a smooth transition by organizing handover sessions between outgoing and incoming student leads. Encourage collaboration during the transition period to ensure a seamless transfer of responsibilities.',
    },
    {
      title: 'Onboarding',
      content:
        'Develop an onboarding program that includes training on IEDC policies, procedures, and expectations. Introduce new student leads to existing team members and provide resources to help them integrate into their roles effectively.',
    },
    {
      title: 'Candidate Feedback',
      content:
        'Collect feedback from candidates regarding their experience with the selection process. Use feedback to identify areas for improvement and implement changes in subsequent selection cycles.',
    },
    {
      title: 'Process Evaluation',
      content:
        'Conduct a post-selection evaluation with the selection committee to assess the effectiveness of the process. Identify strengths and weaknesses to inform improvements in future selection processes.',
    },
    {
      title: 'Regular Update',
      content:
        'Communicate regularly with the IEDC community, providing updates on the progress of the selection process. Maintain transparency in the communication of key milestones and decisions.',
    },
    {
      title: 'Transparency',
      content:
        'Ensure transparency throughout the decision-making process by clearly communicating the criteria, steps, and rationale behind decisions. Address any inquiries or concerns from candidates or the IEDC community with openness and clarity.',
    },
  ];

  const stepsalternate = [
    {
      title: 'Formation of Interest Groups',
      content:
        'Student interest groups are formed under various verticals of IEDC execom positions.',
    },
    {
      title: 'Selection of leads',
      content:
        'Student leads shall be selected from the interest groups. Student leads shall be selected by the students through a transparent election procedure or through nomination and election.',
    },
  ];

  return (
    <div className="bg-white flex flex-col items-center justify-center">
      <div classname ="">
    <a
  href="https://forms.gle/xn7enVn1vu6Dj8YbA"
  className="mx-auto block bg-teal-600 hover:bg-teal-500 mt-20 p-5 md:p-7 text-white font-bold rounded-full"
>
  <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-gray-500 opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
  <span className="relative">Apply Now!</span>
</a>

    </div>

      <div className="p-8 text-black ml-12 mr-12 ">

        <h1 className="text-xl md:text-xl font-bold text-left mt-10">
          Careers at IEDC
        </h1>

        <section className="mb-8 text-black  ">
          <p className="text-lg">
    We're thrilled to invite you to become a part of the IEDC TKMCE family. If you're looking to explore your entrepreneurial spirit and connect with a bunch of like-minded individuals, you've come to the right place. So what do you say? Ready to join the fun? Let's do this! <br/>
    <a href="#positions" className="text-blue-500  mb-2 cursor-pointer">
      browse open positions!
    </a>
          </p>
        </section>
    
        <h1 className="text-xl md:text-xl font-bold text-left mt-10">
      Our Story
    </h1>

        <section className="mb-8 text-black  ">
          <p className="text-lg">
The story of IEDC TKMCE begins with the establishment of the Innovation and Entrepreneurship Development Centre (IEDC) at the TKM College of Engineering in Kollam, Kerala, India. The center was set up with the goal of promoting a culture of innovation and entrepreneurship among students and faculty. Since its inception, IEDC TKMCE has provided a platform for students to develop their innovative ideas into successful startups. The center has also organized various workshops, seminars, and competitions to inspire and equip students with the necessary skills to become successful entrepreneurs. Over the years, IEDC TKMCE has produced several successful startups that have made a significant impact in their respective fields. Today, the center continues to foster a spirit of innovation and entrepreneurship, making it a hub for budding entrepreneurs in Kerala.
    </p>
        
        </section>
    


      
        <img
          className="mx-auto mb-8 "
          src="https://iedc.startupmission.in/assets/im/jpeg/execomstruct.png"
          alt="Execom Structure"
        />
  <h1 id="positions" className="text-xl md:text-2xl font-bold mb-6 text-left ">
          Open Positions
        </h1>

        <div className="text-black grid gap-8 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-5">
          {execomPositions.map((position, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 "
            >
              <p className="font-bold text-xl md:text-2xl mb-4">{position.title}</p>
              <p className="break-words text-lg md:text-md text-black">{position.description}</p>
            </div>
          ))}
        </div>

       
      </div>
    </div>
  );
};

export default ExecomDetails;
