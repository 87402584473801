import React from "react";
import inspire from "../../assets/logo/design.png"
import ignite from "../../assets/logo/fire.png"
import inn from "../../assets/logo/innovate.png"
import Lottie from 'react-lottie';
import animationData from "../../assets/lotties/lines.json";

export const Feature = (props) => {

  return (
    <section className="bg-white mt-20">
      <div className="container px-6 mx-auto">
        <div className="flex flex-col lg:flex-row w-full justify-center items-center">

          <div data-aos-duration="300" data-aos="fade-in" className="w-full sm:w-[350px]  px-10 py-14 h-[400px] border-2 border-gray-200 flex flex-col items-center">
            <span className="inline-block text-border ">
              <img src={inspire} className="w-20 h-20"></img>
            </span>

            <h1 className="text-2xl heading mt-5 font-semibold text-black capitalize ">
              Inspire
            </h1>
            <p className="text-center text-lg text-gray-600 mt-5">
              Get stimulated by the thriving examples of the notable startups incubated from IEDC TKMCE
            </p>
          </div>
          <div data-aos="fade-in" className="w-full sm:w-[350px] my-10 ld:my-0 lg:mx-10 px-10 py-14 h-[400px] border-2 border-gray-200 flex flex-col items-center">
            <span className="inline-block text-border ">
              <img src={inn} className="w-20 h-20"></img>
            </span>
            <h1 className="text-2xl heading mt-5 font-semibold text-black capitalize ">
              Innovate
            </h1>
            <p className="text-center text-lg text-gray-600 mt-5">
              Dive into the world of creativity and innovation, and master your skills through exquisite guidance.
            </p>
          </div>
          <div data-aos="fade-in" className="w-full sm:w-[350px]  px-10 py-14 h-[400px] border-2 border-gray-200 flex flex-col items-center">
            <span className="inline-block text-border ">
              <img src={ignite} className="w-20 h-20"></img>
            </span>
            <h1 className="text-2xl heading mt-5 font-semibold text-black capitalize ">
              Ignite
            </h1>
            <p className="text-center text-lg text-gray-600 mt-5">
              Discover the myriad opportunities offered by us and ignite the spark within you.
            </p>
          </div>

        </div>

      </div>
    </section>
  );
};
