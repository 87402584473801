import React,{useState, createContext, useEffect} from "react";

export const LoaderContext = createContext();

export const LoaderContextProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);
  // const [content, setContent] = useState({title:"Registration",desc:"payment successfull",comp:<div>hello</div>});
  useEffect(()=>{
    setTimeout(()=>{
      setPreLoading(false);
    },4000);
  },[])
  return (
    <LoaderContext.Provider
      value={{
        loadingState:[loading, setLoading],
        preLoadingState:[preLoading, setPreLoading],
        // contentState:[content,setContent],
      }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
};
