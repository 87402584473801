import React from 'react';
import idealab from '../../assets/logo/clients/idea_lab.png'
import SIG from '../../assets/logo/clients/sig.png'
import ecell from '../../assets/logo/clients/E cell logo.png'
import ircell from '../../assets/logo/clients/IR Cell Logo.png'
import aicte from '../../assets/logo/clients/AICTE.png'
import iedc from '../../assets/logo/clients/IEDC Logo Main.png'
import iic from '../../assets/logo/clients/IIC.png'
import ksum from '../../assets/logo/clients/ksum .png'

function WorkedWith(props) {
    return (
        <div data-aos="fade-in" className='min-h-screen w-full bg-white flex flex-col items-center justify-center pt-36'>
            {/* <p  className="uppercase text-red-400 font-bold text-md subHeading my-10">OUR CLIENTS</p> */}
            <h1 data-aos="pt-10 fade-in" className="text-black capitalize text-3xl md:text-5xl px-2 md:px-0 md:w-1/2 font-gillory mb-8 w-full text-center font-bold">
                We’ve worked with big and small alike.
            </h1>

            <div className="md:scale-125 container px-6 md:space-y-10 md:px-12 w-full flex flex-col items-center lg:px-56 mr-5 mb-24">
                {/* <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 place-items-center  gap-y-8 "> */}
                <div className=' flex flex-col items-center md:pt-8 md:flex-row md:justify-evenly '>
                    <div className="scale-125 h-14 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative " >
                        <img src={ksum} className="h-full" alt="" />
                    </div>
                    <div className="h-14 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative md:left-3 " >
                        <img src={iic} className="h-full" alt="" />
                    </div>
                    <div className="h-14 scale-110 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative md:left-5" >
                        <img src={aicte} className="h-full" alt="" />
                    </div>
                    <div className=" scale-[1.4] scale-x-[1.7] h-10 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative md:left-7" >
                        <img src={idealab} className="h-full" alt="" />
                    </div>
                    <div className=" scale-105 h-14 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative md:left-10" >
                        <img src={iedc} className="h-full" alt="" />
                    </div>

                </div>
                <div className=' md:relative md:left-5 flex flex-col items-center  md:flex-row md:justify-evenly md:pt-4'>
                    <div className="scale-[1.85]  h-14 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative md:bottom-3" >
                        <img src={SIG} className="h-full" alt="" />
                    </div>


                    <div className="h-14 sm:h-16 m-2 my-6 md:my-2 md:mx-8 md:relative " >
                        <img src={ircell} className="h-full " alt="" />
                    </div>

                    <div className="scale-125 h-14 sm:h-16 m-2 " >
                        <img src={ecell} className="h-full" alt="" />
                    </div>


                </div>

            </div>
            {/* </div> */}
        </div>


    );
}

export default WorkedWith;