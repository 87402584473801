import React, { useContext, useEffect, useState } from 'react';
import { RiAddLine } from "react-icons/ri";
import { FiMinus } from "react-icons/fi";
import { AiOutlineExclamation } from "react-icons/ai";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { LoaderContext } from '../../context/LoaderContext';
import { PopUpContext } from '../../context/PopUpContext';
import useFetch from '../../hooks/useFetch';
import { BASE_URL } from '../../constants/urls';
import { register_event } from '../../apis/register_event';
import LocalLoader from '../../components/LocalLoader';
import useAuthFetch from '../../hooks/useAuthFetch';
import gatewayResolver from '../../utils/razorpayUtils';
// import { UserContext } from '../context/user';
// import { ModalContext } from '../context/modal';
// import { PopUpContext } from '../context/popup';



export default function CreateTeam(props) {


    const { slug } = useParams();

    const { error, isPending, data: event } = useFetch(BASE_URL + '/events/event/' + slug);
    const { reg_error, reg_isPending, data: reg_event } = useAuthFetch(BASE_URL + '/events/reg-event/' + slug);




    const navigate = useNavigate();

    const { userState, tokenState } = useContext(UserContext);

    const [userDetails, setUserDetails] = userState;
    const [invalidUsers, setInvalidUsers] = useState([]);
    const [token, setToken] = tokenState;

    const { loadingState } = useContext(LoaderContext);

    const { popUpState, popUpContentState } = useContext(PopUpContext);
    const [popUp, setPopUp] = popUpState;
    const [popUpContent, setPopUpContent] = popUpContentState;

    const [title, setTitle] = useState("CREATE TEAM");


    const[message, setMessage] = useState(); //new
    const [teamTitle, setTeamTitle] = useState("");
    // const {tokenState} = useContext(UserContext);
    // const [token,setToken] = tokenState;

    // const { popUpState,contentState,clearContent } = useContext(PopUpContext);
    // const [popUp, setPopUp] =popUpState;
    // const [content, setContent] =contentState;

    // const {showState} = useContext(ModalContext);
    // const [show,setShow] = showState;

    const [disabledAdd, setDisabledAdd] = useState(true);
    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const [teamMembers, setTeamMembers] = useState([]);


    // const {state} = useLocation();
    // const navigate = useNavigate();

    const [checkVerification, setCheckVerification] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState([]);



    useEffect(() => {

        if (reg_event !== null && reg_event !== undefined) {
            setTitle("TEAM VIEW");
            let members = []
            reg_event?.data?.members?.forEach((element, ind) => {
                //if (ind !== 0)
                    members.push(element.email);
            });
            setTeamMembers(members);

        }
    }, [reg_event]);

    const handleChange = (e, index) => {
        const { value } = e.target;
        setTeamMembers(state => {
            let items = [...state];
            items[index] = value;
            return items;
        });
        setCheckVerification(state => {
            let items = [...state];
            items[index] = false;
            return items;
        });
    };
    const handleAdd = (e) => {
        e.preventDefault();
        setTeamMembers(team => [...team, ""]);
        setCheckVerification(team => [...team, false])

    }
    const handleRemove = (e, index) => {
        e.preventDefault();

        setTeamMembers(team => {
            let items = [...team];
            items.splice(index, 1);
            return items;
        });
        setResponseStatus(team => {
            let items = [...team];
            items.splice(index, 1);
            return items;
        });

    };

    const handleSubmit = async (e) => {

        setInvalidUsers([]);
        setLoading(true);

        if (teamMembers.some(member => member.trim() === '')) {
            setPopUpContent({ heading: "NOTICE", description: "Team members cannot be empty!" });
            setMessage('Please add a valid user')
            return;
        } 

        const response = await register_event(token,
            {
                title: teamTitle,
                event_id: event.id,
                team_leader_email: userDetails.email_id,
                members: [teamMembers]
            }
        );
        setLoading(false);//new
        if (response.status === 208) {
            setPopUpContent({ heading: "NOTICE", description: "You have already registered!" });
        } else if (response.status === 400) {
            setPopUpContent({ heading: "NOTICE", description: "Not enough data!" });
            setMessage(response.errMssg.message);  
        }
        else if (response.status === 402) {
            setPopUpContent({ heading: "NOTICE", description: "Failed to create team." })
            setInvalidUsers(response.errMssg.invalid_emails);
            setMessage("");
        }
        else if (response.status === 202 ) {
            setPopUpContent({ heading: "NOTICE", description: "Registered successfully" });
            navigate("/events");
            setPopUp(true);
        }
        else if (response.status === 201) {

            if (title !== "TEAM VIEW") {
                setPopUpContent({ heading: "NOTICE", description: "Team created successfully." });
                setPopUp(true);
            }
            await gatewayResolver(response.status === 201 ? response.response : response.errMssg, token, setUserDetails, setPopUpContent, setPopUp, navigate, slug);


        }
        else {
            setPopUpContent({ heading: "NOTICE", description: "Try again! Something went wrong!" })
        }
        setPopUp(true);


    }




    useEffect(() => {
        if (event && teamMembers) {
            if (event.max_members_in_team <= teamMembers.length + 1)
                setDisabledAdd(true);
            else
                setDisabledAdd(false);
        }

    }, [event, teamMembers]);

    useEffect(() => {
        if (event && teamMembers) {
            if (event.min_members_in_team > teamMembers.length + 1 || event.max_members_in_team < teamMembers.length + 1)
                setDisabledSubmit(true);
            else
                setDisabledSubmit(false);
        }

    }, [event, teamMembers]);

    return (
        <form data-aos="fade-zoom-in" data-aos-duration="300" className='w-full min-h-screen text-small py-4 mt-10  md:px-8'>
            <form className={`bg-primary-dark border h-full border-secondary-dark rounded-xl p-2 flex flex-col items-center relative`}>
                <p className='w-full text-center text-smedium md:text-medium mt-4 headerFont'>{title}</p>

                {error && <div className='p-2'>{error}</div>}
                {isPending && <div className='p-2 relative'><LocalLoader /></div>}
                <div className='w-full md:w-1/2 p-4 flex flex-col'>
                    <div className='w-full'>
                        <div className=" w-full flex items-center mt-3">
                            <div className="h-6 text-xs font-bold uppercase leading-8 text-secondary-light">
                                EVENT :
                            </div>
                            <div className="h-6 ml-5 text-xs font-bold uppercase leading-8 text-secondary-light">
                                {event?.event_name}
                            </div>

                        </div>
                        {/* <div className=" w-full flex items-center mt-3">
                            <div className="h-6 text-[10px] font-bold leading-8 text-secondary-light opacity-70 z-0">
                                <p>//{event.team_id}</p>
                            </div>  
                        </div> */}
                    </div>

                    <div className='w-full'>
                        <div className="w-full md:w-11/12">
                            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-secondary-light">
                                Team Title
                            </div>
                            <div className="my-2 p-1 flex border border-[#615f55] rounded">
                                <input
                                    value={teamTitle}
                                    type='text'
                                    onChange={(e) => setTeamTitle(e.target.value)}
                                    className="w-full text-lg p-1 px-2 text-secondary-dark placeholder-secondary-dark bg-transparent outline-none font-RobotoMono"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='w-full'>
                        <div className="w-full md:w-11/12">
                            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-secondary-light">
                                Leader
                            </div>
                            <div className="my-2 p-1 flex border border-[#615f55] rounded">
                                <input
                                    value={userDetails?.email_id}
                                    disabled={true}
                                    className="w-full text-lg p-1 px-2 text-secondary-dark placeholder-secondary-dark bg-transparent outline-none font-RobotoMono"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-5 w-full'>
                        {title !== "TEAM VIEW" ?
                            <div className="my-3 h-6 text-xs flex flex-row items-center font-bold uppercase leading-8 text-secondary-light">

                                <button disabled={disabledAdd} className={`${disabledAdd ? "opacity-40" : "group hover:bg-secondary-dark"} text-small mb-2 flex px-4 font-bold border border-secondary-dark rounded items-center`} onClick={(e) => handleAdd(e)}>
                                    <RiAddLine className='text-xl group-hover:text-primary-dark' />
                                    <p className='ml-5 text-xsmall group-hover:text-primary-dark'>MEMBER</p>
                                </button>
                            </div>
                            :
                            <p className='mt-3 h-6 text-xs font-bold uppercase leading-8 text-secondary-light'>MEMBERS</p>

                        }

                        <div className="w-full ">
                            {teamMembers.length > 0 && teamMembers.map((item, index) => {
                                return (
                                    <div key={index} className={`mb-4 flex flex-col  w-full relative`}>
                                        <div className={`flex border ${checkVerification[index] === true ? 'border-[#615f55]' : 'border-[#615f55]'} rounded w-full md:w-11/12 p-1`}>
                                            <input
                                                required={true}
                                                onChange={(e) => handleChange(e, index)}
                                                value={teamMembers[index]}
                                                disabled={title === "TEAM VIEW" ? true : false}
                                                type="email"
                                                placeholder="Enter email ID"
                                                className="w-full appearance-none text-lg p-1 px-2 text-secondary-dark placeholder-secondary-dark bg-transparent outline-none font-RobotoMono"
                                            />
                                            {title !== "TEAM VIEW" && <button disabled={loading ? true : false} onClick={(e) => handleRemove(e, index)}>
                                                <FiMinus className={`${loading && "opacity-40"} md:ml-5 text-xl font-bold  rounded`} />
                                                {/* ADD */}
                                            </button>}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>


                       {message && (
                            <div className="w-full text-red-400">
                                <p className='pb-1'>{message}</p>
                            </div>
                        )}

                        {invalidUsers && invalidUsers.length > 0 &&
                            <div className='flex flex-col items-start w-full text-red-400'>

                                {invalidUsers.map((user, ind) => {
                                    return (
                                        <p className='pb-1'>{user}</p>
                                    )
                                })}
                                <p>Ask them to login IEDC before creating the team.</p>
                            </div>
                        }
                        {
                            (reg_event !== null && reg_event !== undefined && (reg_event?.data?.payment_status || reg_event?.data?.event?.is_paid_event===false)) ?
                                <p className='mt-3 h-6 text-xs font-bold uppercase leading-8 text-secondary-light'>PAYMENT STATUS:PAID</p>
                                :

                                <div className='w-full flex justify-center'>
                                    <button disabled={disabledSubmit} onClick={(e) => {
                                        e.preventDefault();
                                        if (title === "TEAM VIEW")
                                            handleSubmit();
                                        else {
                                            if (window.confirm("This cant be modified\nAre you sure to submit?")) {
                                                handleSubmit();
                                            }
                                        }
                                    }
                                    } className={`font-bold border border-secondary-dark rounded px-4 py-2 my-5 text-small flex items-center justify-center ${disabledSubmit ? 'opacity-40' : 'hover:bg-secondary-dark hover:text-primary-dark'} `}>
                                        <p>{title === "TEAM VIEW" ? "PROCEED TO PAYMENT" : "SUBMIT"}</p>
                                    </button>

                                </div>
                        }

                    </div>
                </div>
            </form>
        </form>
    );
}
