import React, { useState } from 'react';
import LocalLoader from '../components/LocalLoader';
import { BASE_URL } from '../constants/urls';
import useFetch from '../hooks/useFetch';
import plus from '../svgs/plus-pattern.png'



function DareToDevelop() {
    const { error, isPending, data: episodes } = useFetch(BASE_URL + "/dare2develop");

    const [selected, setSelected] = useState("all");
    return (
        <div className='h-full w-full bg-dark flex flex-col items-center justify-between  bg-gradient-to-r from-[#b71825] to-[#6c0f17] min-h-screen'>
            <div className='w-full pl-10 hidden md:flex  min-h-[30vh] flex-col sm:flex-row justify-start items-center p-5 lg:px-14 py-5 mt-24 mb-10'>
                <img alt="icon" src="/images/dare2develop/icon.jpg" className='h-48 w-48 bg-dark rounded-md shadow-xl' />


                <div className='flex flex-col items-start text-slate-300 justify-between h-full pt-10 sm:pt-0 sm:pl-5 lg:pl-10'>
                    <p className='font-bold hidden sm:block text-sm opacity-[0.7]'>PODCAST</p>
                    <p className='font-bold text-2xl sm:text-5xl lg:text-7xl'>Dare2Develop</p>
                    <p className='font-bold text-lg hidden sm:block sm:text-2xl opacity-[0.7]'>IEDC TKMCE</p>
                </div>
                <img alt="" src={plus} class="absolute opacity-[0.3] h-60 hidden lg:block right-0 top-0 mt-24" />

            </div>
            <div className='w-full p-5  pt-10 bg-gradient-to-b from-[#0000008f] to-[#101010] pb-28'>

                <div className='w-full md:pl-14 flex md:hidden flex-col sm:flex-row justify-start items-center p-5 mb-14 '>
                    <img alt="icon" src="/images/dare2develop/icon.jpg" className='h-48 w-48 bg-dark rounded-md shadow-xl' />
                    <div className='flex flex-col items-start text-white justify-between h-full pt-10 sm:pt-0 sm:pl-5 lg:pl-10'>
                        <p className='font-bold text-3xl sm:text-5xl'>Dare2Develop</p>
                    </div>
                    <img alt="" src={plus} class="absolute opacity-[0.3] h-60 hidden lg:block right-0 top-0 mt-24" />

                </div>

                <div className='w-full flex lfex-row items-center justify-end text-white pb-10 md:px-10 font-bold'>
                    <button onClick={() => setSelected("all")} className={`mr-5 ${selected === "all" && "underline"}`}>All</button>
                    <button onClick={() => setSelected("english")} className={`mr-5 ${selected === "english" && "underline"}`}>English</button>
                    <button onClick={() => setSelected("malayalam")} className={`${selected === "malayalam" && "underline"}`}>മലയാളം</button>
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-6 md:px-10'>

                    {episodes !== null &&
                        episodes?.data.map((item, index) => (
                            (item.language.toLowerCase() === selected || selected === "all") && <iframe data-aos="fade-in" data-aos-duration="300" title={index} className=' bg-[#282828] mb-1 rounded-xl mr-2' key={index} src={`https://open.spotify.com/embed/episode/${item.embedded_code}?utm_source=generator&theme=0`} width="100%" height="250" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>


                        ))
                    }
                    {error && <div className='p-2 w-full text-center text-white'>{error}</div>}
                    {isPending && <div className='p-2 w-full text-center relative'><LocalLoader /></div>}
                    {!error && episodes?.data?.length === 0 && <div className='p-2 w-full text-center text-white'>No episodes</div>}


                </div>

            </div>
        </div>
    );
}

export default DareToDevelop;